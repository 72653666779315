/* This is addithion Style by Hassan Ali To Override The Defult Styling */
.title {
  letter-spacing: 0;
}

.sup-title {
  letter-spacing: 0;
}


/* #region Loader */
.custom-loader {

  width: 100%;
  height: 100vh;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f2f3;
}


@keyframes ldio-c7qsru5rz7 {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

.ldio-c7qsru5rz7 div {
  box-sizing: border-box !important
}

.ldio-c7qsru5rz7>div {
  position: absolute;
  width: 144px;
  height: 144px;
  top: 28px;
  left: 28px;
  border-radius: 50%;
  border: 16px solid #000;
  border-color: #0446f1 transparent #0446f1 transparent;
  animation: ldio-c7qsru5rz7 1s linear infinite;
}

.ldio-c7qsru5rz7>div:nth-child(2) {
  border-color: transparent
}

.ldio-c7qsru5rz7>div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}

.ldio-c7qsru5rz7>div:nth-child(2) div:before, .ldio-c7qsru5rz7>div:nth-child(2) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -16px;
  left: 48px;
  background: #0446f1;
  border-radius: 50%;
  box-shadow: 0 128px 0 0 #0446f1;
}

.ldio-c7qsru5rz7>div:nth-child(2) div:after {
  left: -16px;
  top: 48px;
  box-shadow: 128px 0 0 0 #0446f1;
}

.loadingio-spinner-dual-ring-x0spjb8qpv {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #f1f2f3;
}

.ldio-c7qsru5rz7 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-c7qsru5rz7 div {
  box-sizing: content-box;
}

/* #endregion  Loader */

/*#region custom-header*/
.cm-header {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  z-index: 999;
  padding: 1rem 0;
  font-family: 'codec_pro', sans-serif;
}

.cm-header.fixed {
  position: fixed;
  background-color: #232323;
  box-shadow: 0px 0px 7px 0px rgb(255 255 255 / 26%);
}


.cm-header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cm-header-logo img {
  width: 100px;
}

.cm-header-nav ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.cm-header-nav ul li {
  padding: 0 1rem;
}

.cm-header-nav ul li a {
  font-size: 1.5rem;
}

.cm-header-nav ul li:after {
  background-color: #0446F1;
  content: " ";
  width: 0;
  height: 3px;
  display: block;
  transition: all 0.5s ease;
}

.cm-header-nav ul li:hover:after {
  width: 100%;
}

.cm-header-nav ul li:hover a {
  opacity: 0.8;
}


.cm-header-nav .mobile-button {
  display: none;
  width: 26px;
  height: 26px;
  float: right;
  background-color: transparent;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cm-header-nav .mobile-button:before, .cm-header-nav .mobile-button:after, .cm-header-nav .mobile-button span {
  background-color: var(--main-color);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.cm-header-nav .mobile-button:before, .cm-header-nav .mobile-button:after {
  content: '';
  position: absolute;
  top: 0;
  height: 3px;
  width: 100%;
  left: 0;
  top: 50%;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.cm-header-nav .mobile-button span {
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0;
  top: 50%;
  overflow: hidden;
  text-indent: 200%;
}

.cm-header-nav .mobile-button:before {
  -webkit-transform: translate3d(0, -7px, 0);
  -moz-transform: translate3d(0, -7px, 0);
  transform: translate3d(0, -7px, 0);
}

.cm-header-nav .mobile-button:after {
  -webkit-transform: translate3d(0, 7px, 0);
  -moz-transform: translate3d(0, 7px, 0);
  transform: translate3d(0, 7px, 0);
}

.cm-header-nav .mobile-button.active span {
  opacity: 0;
}

.cm-header-nav .mobile-button.active:before {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  -moz-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
}

.cm-header-nav .mobile-button.active:after {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  -moz-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg);
}





/*#endregion custom-header*/



/*#region .main-header*/
.header .main-header .main-nav {
  top: 70px;
}

.header .main-header .main-header-contact .call {
  background: none;
  padding: 24px 20px 20px 20px !important;
  display: flex;
}

.header .main-header .main-header-contact .call:hover {
  background: none !important;
  transform: scale(1.1);
}

.header .main-header .main-header-contact .call img {
  top: 0% !important;
  left: 0% !important;

}

.contact-whatsapp {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 2rem;
  bottom: 3rem;
  z-index: 9999;
  animation: up-and-down 3s infinite alternate;
}

/*#endregion .main-header*/

/*#region .tp-bullet*/

.section-custom-slider img.mobile {
  display: none;
}

.section-custom-slider img.desktop {
  display: block;
}

.section-slider.style-2 .tp-bullet {
  background: rgba(17, 22, 30, 0.102);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;

}

.section-slider.style-2 .tp-bullet:hover {
  background: var(--main-color);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.section-slider.style-2 .tp-bullet.selected {
  background: var(--main-color);
}

.section-slider.style-3 {
  margin-top: -158px;
}

.section-slider.style-3 .tp-mask-wrap {
  padding-top: 130px;
  margin-left: -31px;
}

.tp-bullets.custom.horizontal.nav-pos-hor-center.nav-pos-ver-bottom.nav-dir-horizontal {
  width: 100% !important;
  height: fit-content !important;
  transform: none !important;
  left: 0 !important;
  visibility: hidden;
  visibility: inherit !important;
  opacity: 1 !important;
  position: absolute;
  bottom: 10% !important;
  display: flex;
  justify-content: center;


}

.section-slider.style-2 .tp-bullets.custom.horizontal.nav-pos-hor-center.nav-pos-ver-bottom.nav-dir-horizontal {
  left: 12.3% !important;

}

.tp-bullet {
  overflow: hidden;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.102);
  cursor: pointer;
  display: inline-block;
  margin: 1rem;
  margin-bottom: 20px;
  -webkit-transition: all .5s ease-in-out !important;
  -moz-transition: all .5s ease-in-out !important;
  -ms-transition: all .5s ease-in-out !important;
  -o-transition: all .5s ease-in-out !important;
  transition: all .5s ease-in-out !important;

}

.tp-bullet.selected, .tp-bullet:hover {
  width: 20px;
  height: 10px;
  background: var(--main-color);
  -webkit-transition: all 1s ease-in-out !important;
  -moz-transition: all 1s ease-in-out !important;
  -ms-transition: all 1s ease-in-out !important;
  -o-transition: all 1s ease-in-out !important;
  transition: all 1s ease-in-out !important;
}

/*#endregion .tp-bullet*/

/*#region .section-work*/

.section-work.style-2 .list-box-work .box-work .box-item .title-box:before {
  width: 0 !important;
  height: 0 !important;
}

.section-portfolio.style-2 .list-portfolio .themesflat-portfolio:nth-child(6) {
  margin-right: 0;
  margin-top: 0px !important;
}

/*#endregion .section-work*/

/*#region .section-video*/
.section-video {
  padding: 3rem 0;
}

.section-video .box-video .box {
  background: none !important;
  padding: 0 !important;
  width: 100% !important;
  max-height: 600px !important;
  /* overflow: hidden !important; */
  position: relative !important;

}

.section-video .box-video .box:before {
  content: '';
  position: absolute;
  z-index: 999;
  top: -10px;
  left: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--main-color);
}


.section-video .box-video .box img {
  width: 100%;
  /* height: 550px !important; */
  object-fit: contain;

}

.section-video .box-video .box.video-active {
  display: none;

}

.section-video .box-video .video-frame-box {
  display: none;
}

.section-video .box-video .video-frame-box iframe {
  width: 100%;
  min-height: 400px;
  border-radius: 10px;
}

.section-video .box-video .video-frame-box.video-active {
  display: block;
}





/*#endregion .section-video*/

/*#region .section-counter*/
.section-counter .heading-top h3.title {
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.3em;
  padding-bottom: 0px !important;
  color: #fff;
}

/*#endregion .tp-bullet*/

/*#region .section-about */
.page-title .main-page h2.title:before {

  width: 0px !important;
  height: 0px !important;
  background: none !important;

}


.section-about .intro-content h3.title::before {
  width: 0px !important;
  height: 0px !important;
}

.section-about .info-chooses .card-icon {
  font-size: 30px;
  transition: all 1s ease;
  display: block;
  margin: 1rem auto;
  border-radius: 50%;
  background-color: #0446F1;
  padding: 2rem;
  width: 8rem;
}

.section-about .info-chooses .card-icon i {

  color: #e0e0e0;
  transition: all 1s ease;
}

.section-about .info-chooses p.text {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.75em;

  padding-inline-end: 75px;
  padding-top: 16px;
  padding-bottom: 24px;
  color: #777777;

}

.section-about .info-chooses div.card {
  transition: all 0.5s ease;
  height: 100%;
}

.section-about .info-chooses div.card:hover {
  box-shadow: 1px 1px 10px #444;
}

.section-about .info-chooses div.card:hover .card-icon i {

  transform: rotate(360deg);

}

.section-about .info-chooses p.card-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #777777;
  text-align: center;
}



/*New About Style*/

.section-about-new-style .image-about {
  height: 100% !important;
  width: 70%;
  margin: auto;
}


.section-about-new-style .intro-content .about-titles {
  display: flex;
  width: 100%;
  border-bottom: 3px solid #f1f2f3;
  margin: 3rem 0 1rem;

}

.section-about-new-style .intro-content .about-titles h5 {
  border-bottom: 3px solid transparent;
  padding: 1rem 2rem;
  cursor: pointer;
  transition: all .5s ease;
}

.section-about-new-style .intro-content .about-titles h5.active {
  border-color: #0446F1;
}

.section-about-new-style .intro-content .text {
  display: none;
  text-align: justify;
}

.section-about-new-style .intro-content .about-pharagraph h5 {
  font-size: 3rem;
  padding-bottom: 1rem;
  text-transform: capitalize;
}

.section-about-new-style .intro-content .about-pharagraph .custom-icon-list {
  position: relative;
  padding: 2rem 0;
}

.section-about-new-style .intro-content .about-pharagraph .custom-icon-list li {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.section-about-new-style .intro-content .about-pharagraph .custom-icon-list li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0446F1;
  border-radius: 50%;
  color: #fff;
  font-size: 7px;
}


.section-about-new-style .intro-content .text.active {
  display: block;
}








/*#endregion .section-about */

/*#region .section-portfolio*/

.section-portfolio .list-portfolio .themesflat-portfolio .boxitem .box-item-content .content-box:after {
  content: none;
  width: 0px !important;
  height: 0px !important;
  background: none !important;

}

.boxitem .box-item-content .content-box h5.title:before {
  content: none;
  width: 0px !important;
  height: 0px !important;
  background: none;
}

.boxitem .box-item-content .content-box:after {
  content: none !important;
  width: 0px !important;
  height: 0px !important;
  background: none !important;

}

.section-portfolio .list-portfolio .themesflat-portfolio .boxitem .box-item-content .content-box:after {

  width: 0px !important;
  height: 0px !important;
  background: none !important;

}

.section-portfolio .list-portfolio .themesflat-portfolio .boxitem .box-item-content .content-box h5.title:before {

  width: 0px !important;
  height: 0px !important;

}

.section-portfolio .themesflat-portfolio .project-item .boxitem .imgbox {
  position: relative;
}

.section-portfolio .themesflat-portfolio .project-item .boxitem .cover {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  /* border-radius: 10px; */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
  opacity: 0;
  font-size: 24px;
  font-weight: 700;
  transition: opacity 0.3s ease-in-out;
}

.section-portfolio .themesflat-portfolio:hover .project-item .boxitem .cover {

  opacity: 1;

}


/*#endregion .section-portfolio*/

/*#region section-portfolio  Project Filter*/
.filter-btn {
  border-bottom: 2px solid var(--dark-color)
}

.courseslinks {
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: start;
  flex-wrap: wrap;
}

.courseslinks button {
  font-size: 1.5rem;
  padding: 1rem 2rem;
  transition: all .5s ease;
}

.courseslinks button:hover {

  color: var(--main-color);
  transform: scale(1.1);
}

.active_btn {
  border-radius: 10px;
  background-color: var(--main-color) !important;
  color: var(--light-color) !important;
}

.filterDiv {
  display: none !important;

}

/* The "show" class is added to the filtered elements */

.show_card {
  display: flex !important;

}

.card-title {
  width: 100% !important;
}

.card-title span {
  display: block;
  font-weight: bold;
  padding: 2rem 0;
}

.filterDiv .card-title span {
  letter-spacing: 0;
  font-family: 'codec_pro';
  font-size: 3rem
}

.section-portfolio .list-portfolio {
  display: flex;
  gap: 5rem 5%;
  flex-wrap: wrap;
}

.section-portfolio .list-portfolio .themesflat-portfolio {
  margin-right: 0 !important;
  flex-basis: 30% !important;
}

#portfoilo-home .list-portfolio .themesflat-portfolio .boxitem .imgbox img {
  filter: grayscale(0.5);
}

#portfoilo-home .list-portfolio .themesflat-portfolio .boxitem:hover .imgbox img {
  filter: grayscale(0);
}

.section-portfolio .list-portfolio .themesflat-portfolio .boxitem:hover .imgbox img.light {
  opacity: 1;
  filter: grayscale(0);
}

.section-portfolio .list-portfolio .themesflat-portfolio .boxitem .box-item-content .content-box {
  opacity: 1 !important;
  cursor: pointer;
}

.section-portfolio .list-portfolio .themesflat-portfolio .boxitem .imgbox {
  cursor: pointer;
}


#card-contaner-grid {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
  flex-wrap: wrap;
  gap: 3rem;
}

/*#endregion section-portfolio*/

/*#region portfolio-details  */

/* #carouselExampleIndicators .carousel-item {
  height: 60vh !important;
}
#carouselExampleIndicators .carousel-inner {
  height: 60vh !important;
}
#carouselExampleIndicators .carousel-inner .carousel-item img{
  height: 100% !important;

  width: auto !important;
} */
.carousel-indicators {
  position: static;
  bottom: -15rem !important;
  flex-wrap: nowrap !important;
  margin: 0 !important;
  width: 100%;
}

.carousel-indicators>button {
  width: 20rem !important;
  height: 10rem !important;

}

.carousel-indicators>button {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

/*#endregion section-portfolio*/

/*#region section-facts*/

.section-facts {
  position: relative;
  margin-top: 30px;
  z-index: 99;
  background: url("../images/portfolio/bg-portfolio.png") no-repeat;
  background-size: cover;
  background-position: center center;
}

.section-facts .facts-content {
  text-align: center;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 982px) {
  .section-facts .facts-content  {
    flex-direction: column;
  }
}
.section-facts .facts-content h2.heading {
  font-size: 48px;
  line-height: 1.25em;
  color: #fff;
  text-transform: capitalize;
  text-decoration: none;
  /* padding-top: 56px;
  padding-bottom: 32px; */
  padding: 0 !important;
  letter-spacing: -0.1px;
}

.section-facts .facts-content h2 span {
  color: #0446F1;

}

.section-facts .facts-content .btn-main {
  padding-bottom: 40px;
}

.section-facts .facts-content .form-contact {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .section-facts .facts-content .form-contact input, button {
  height: 50px;
} */

.section-facts .facts-content .form-contact input {
  min-width: 400px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1px;
  margin-left: 10px;
  border: 1px solid #333;
}

.section-facts .facts-content .form-contact input:focus {
  border-color: #777777;
}

.section-facts .facts-content .form-contact button {
  border-radius: 5px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1px;
  overflow: hidden;
  background-color: #0446F1;
  margin-left: 10px;
  padding: 1rem 2rem;
}




/*#endregion section-facts*/

/*#region section-carrer */
.secyion-carrer .cm-table {
  border: none;
}

.secyion-carrer .cm-table th, td, tr {
  border: none;
}

.secyion-carrer .cm-table td, .secyion-carrer .cm-table th {
  padding: 2rem 1rem;
}

.secyion-carrer .cm-table th {

  text-transform: uppercase;
  font-family: 400, sans-serif;
  font-size: 1.4rem;
  color: #333333;
  letter-spacing: 0.1px;
}

.secyion-carrer .cm-table td {
  font-size: 1.4rem;
  color: #333333;
  letter-spacing: 0.1px;
}

.secyion-carrer .cm-table .postions-title {
  text-transform: uppercase;
  font-size: 2rem;
  color: #0446F1;
  text-decoration: underline;
}

.secyion-carrer .cm-table td a {

  background-color: #0446F1;
  padding: 1.3rem;
  color: #fff;
  font-size: 1.5rem;
  border-radius: 5px;
  border: 1px solid transparent;

}

.secyion-carrer .cm-table td a:hover {

  background-color: #fff;
  color: #0446F1;
  border-color: #11161e;


}



/*#endregion section-carrer */







/*#region Footer*/

.footer .section-main-footer .list-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* .footer .section-main-footer .list-footer .widget-about {
    flex-basis: 3 !important;
  } */

/* .footer .section-main-footer .list-footer .widget-link {
    flex-basis: 1 !important;
  } */


/*#endregion Footer*/



/*#region Error*/
.error_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error_div p {
  width: 60%;
  margin: auto;
  text-align: center;
}

.error_div p a {
  color: #0446F1;

}

.error_div p a:hover {
  color: #333;

}

/*#endregion Error*/

/*-----------------------------**/
/*#region section-slider*/
.section-slider {
  min-height: 100vh !important;
}

.section-slider .forcefullwidth_wrapper_tp_banner, .tp-fullwidth-forcer, .tp-container {
  height: 100% !important;
  background-color: #333;
}

.section-slider .tp-revslider-mainul .tp-bgimg {
  min-height: 115vh !important;

}


/* .section-slider {
  position: relative;
  margin-top: -200px;
  overflow: hidden;
  height: 950px !important;
}

.section-slider .tp-parallax-wrap {
  z-index: 99 !important;
}

.section-slider .rev_slider_wrapper.fullwidthbanner-container {
  height: 950px !important;
}

.section-slider div#rev-slider1 {
  max-height: 950px !important;
  height: 950px !important;
}

.section-slider .title-slide-2 {
  padding-top: 118px !important;
}

.section-slider .style-2 {
  color: #11161e !important;
}

.section-slider .tp-mask-wrap {
  padding-top: 130px;
  margin-left: -31px;
}

.section-slider img.before-slide {
  position: absolute;
  z-index: 99;
  bottom: 0;
  left: 19.4%;
}

.section-slider img.after-slide {
  position: absolute;
  z-index: 99;
  top: 20%;
  right: 0;
}

.section-slider .menu-slide {
  position: absolute;
  bottom: 41px;
  right: 150px;
}

.section-slider .menu-slide .list-menu-slide {
  margin: 0;
}

.section-slider .menu-slide .list-menu-slide::before {
  content: '';
  position: absolute;
  z-index: 9;
  top: -91px;
  right: 2px;
  width: 22px;
  height: 23px;
  background: var(--main-color);
  border-radius: 50%;
  -webkit-animation: zoom-fade-two 5s infinite linear;
  animation: zoom-fade-two 5s infinite linear;
}

.section-slider .menu-slide .list-menu-slide li {
  display: inline-block;
  padding-left: 30px;
}

.section-slider .menu-slide .list-menu-slide li img {
  margin-top: -8px;
}

.section-slider .menu-slide .list-menu-slide li a {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

.section-slider .menu-slide .list-menu-slide li a:hover {
  color: var(--main-color);
}

.section-slider .tp-mask-wrap {
  overflow: inherit !important;
}

.section-slider .tp-caption.tp-resizeme.text-white.font-heading.font-weight-600 {
  letter-spacing: 10.7px !important;
  color: var(--main-color) !important;
}

.section-slider .tp-caption.tp-resizeme.text-white.font-heading.font-weight-700 {
  font-weight: bold;
  margin-top: 14px !important;
}

.section-slider .tp-caption.padding-top-100.padding-left-10.text-slide {
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  color: #b0b0b0 !important;
}

.section-slider .tp-caption.tp-resizeme.text-white.font-heading.font-weight-700.padding-top-100.text-slide {
  color: #b0b0b0 !important;
  margin-top: 267px !important;
  margin-left: 6px !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400 !important;
}

.section-slider .btn-slider {
  padding-top: 125px !important;
  padding-left: 10px !important;
}

.section-slider.style-2 .tp-bullet {
  background: rgba(17, 22, 30, 0.102);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.section-slider.style-2 .tp-bullet:hover {
  background: var(--main-color);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.section-slider.style-2 .tp-bullet.selected {
  background: var(--main-color);
}

.section-slider.style-3 {
  margin-top: -158px;
}

.section-slider.style-3 .tp-mask-wrap {
  padding-top: 130px;
  margin-left: -31px;
}

.tp-bullets.custom.horizontal.nav-pos-hor-center.nav-pos-ver-bottom.nav-dir-horizontal {
  visibility: inherit !important;
  opacity: 1 !important;
  position: absolute;
  top: 91.4% !important;
  left: 15.9% !important;
}

.section-slider.style-2 .tp-bullets.custom.horizontal.nav-pos-hor-center.nav-pos-ver-bottom.nav-dir-horizontal {
  left: 12.3% !important;
}

.tp-bullet {
  width: 60px;
  height: 2px;
  position: relative;
  background: rgba(255, 255, 255, 0.102);
  cursor: pointer;
  left: 0 !important;
  top: 0;
  margin-bottom: 20px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.tp-bullet.selected, .tp-bullet:hover {
  width: 100px;
  height: 2px;
  background: var(--main-color);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
} */

/*#endregion section-slider*/

/* html[lang="ar"] #main-nav #menu-primary-menu  {
  display: flex;

}
html[lang="ar"] #main-nav #menu-primary-menu li a{
  text-align: right !important;
}
html[lang="ar"] #main-nav #menu-primary-menu  li:first-child {
  order: 6;
}
html[lang="ar"] #main-nav #menu-primary-menu  li:nth-child(2) {
  order: 5;
}
html[lang="ar"] #main-nav #menu-primary-menu  li:nth-child(3) {
  order: 4;
}
html[lang="ar"] #main-nav #menu-primary-menu  li:nth-child(4) {
  order: 3;
}
html[lang="ar"] #main-nav #menu-primary-menu  li:nth-child(5) {
  order: 2;
}
html[lang="ar"] #main-nav #menu-primary-menu  li:nth-child(6) {
  order: 1;
} */












.padding-top-0 {
  padding-top: 0px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.padding-top-30 {
  padding-top: 30px !important;
}

.padding-top-40 {
  padding-top: 40px !important;
}

.padding-top-50 {
  padding-top: 50px !important;
}

.padding-top-60 {
  padding-top: 60px !important;
}

.padding-top-70 {
  padding-top: 70px !important;
}

.padding-top-80 {
  padding-top: 80px !important;
}

.padding-top-90 {
  padding-top: 90px !important;
}

.padding-top-100 {
  padding-top: 100px !important;
}

.padding-top-150 {
  padding-top: 150px !important;
}

.padding-top-200 {
  padding-top: 250px !important;
}


.animation_duration {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.animation_delay {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animation_timing {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}



/** ----------------------- Single Project -------------------------**/
/*#region Section-project*/

.section-project-details .main-project-details .custon-project-content .sub-title {
  font-size: 14px;
  color: #777777;
  line-height: 2.143;
}

.section-project-details .main-project-details .custon-project-content .custom-badge {
  padding: 1rem;
  text-transform: uppercase;
  background-color: #0446F1;
}

.section-project-details .main-project-details .custon-project-content .description {
  text-align: justify;
  font-size: 14px;
  color: #232323;
  line-height: 2.143;
}



.cutom-slidebar .box-author {
  background: #fff;
  font-size: 16px;
}

.cutom-slidebar .box-author li {
  border: 1px solid #e0e0e0;
  padding: 20px;
  color: #777777;
  text-transform: capitalize;
}

.cutom-slidebar .box-author li b {
  color: #232323;
}

html[lang="en"] .cutom-slidebar .box-author li b {
  padding-left: 10px;
}

html[lang="ar"] .cutom-slidebar .box-author li b {
  padding-right: 10px;
}




/* 
.cutom-slidebar.box-author li.name {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  padding-top: 22px;
  padding-left: 6px;
}

.cutom-slidebar .box-author p.text {
  font-family: 'Oswald', sans-serif;
  padding-top: 19px;
  font-size: 16px;
  letter-spacing: -0.2px;
  line-height: 1.7em;
} */




/*#endregion Section-project*/



/**------------ Team ---------------**/



.section-team .custom-list-team .box-item-team {
  position: relative;
  /* margin-right: 30px; */
}

.section-team .custom-list-team .box-item-team img {
  border-radius: 5px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

}

.section-team .custom-list-team .box-item-team:nth-child(4) {
  margin-right: 0;
}

/* .section-team .custom-list-team .box-item-team .imgbox {
  padding-bottom: 27px;
} */

.section-team .custom-list-team .box-item-team .info {
  position: absolute;
  z-index: 9;
  bottom: 82px;
  left: 50%;
  transform: translateX(-50%);
  width: 230px;
  height: 70px;
  border-radius: 3px;
  background: #fff;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.section-team .custom-list-team .box-item-team .tag-info {
  position: absolute;
  z-index: 9;
  bottom: 82px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  padding: 1rem;
  border-radius: 3px;
  background: #fff;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  opacity: 1;
}

.section-team .custom-list-team .box-item-team .info::after {
  content: '';
  width: 36px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.section-team .custom-list-team .box-item-team .info .list-social {
  display: flex;
  margin-top: 15px;
}

.section-team .custom-list-team .box-item-team .info .list-social li {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  -webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
  transition: opacity 0.2s, transform 0.35s;
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  background: rgba(17, 22, 30, 0.102);
}

.section-team .custom-list-team .box-item-team .info .list-social li:hover {
  background: var(--main-color);
}

.section-team .custom-list-team .box-item-team .info .list-social li:hover i::before {
  color: #11161e;
}

.section-team .custom-list-team .box-item-team .info .list-social li i {
  background: transparent;
  padding-top: 30px;
}

.section-team .custom-list-team .box-item-team .info .list-social li i::before {
  color: #777777;
}

.section-team .custom-list-team .box-item-team .info .list-social li .fa-facebook-f:before, .section-team .custom-list-team .box-item-team .info .list-social li .fa-facebook:before {
  content: "\f39e";
}

.section-team .custom-list-team .box-item-team .info .list-social li a {
  transform: translateY(-50%);
}

.section-team .custom-list-team .box-item-team .tag-info h6.name {
  font-size: 18px;
  line-height: 1em;
  font-weight: 600;
  padding-bottom: 15px;
}

.section-team .custom-list-team .box-item-team .tag-info p.branch {
  font-size: 12px;
  line-height: 1em;
  color: #777777;

}

.section-team .custom-list-team .box-item-team:hover .info {
  bottom: 95px;
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.section-team .custom-list-team .box-item-team:hover img {
  transform: scale(0.99);
}

.section-team .custom-list-team .box-item-team:hover .tag-info {
  bottom: 0px;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}


.section-team .custom-list-team .box-item-team:hover .info li {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.section-team .custom-list-team .box-item-team:hover .info li:nth-child(4) {
  -webkit-transition-delay: 0.20s;
  transition-delay: 0.35s;
}

.section-team .custom-list-team .box-item-team:hover .info li:nth-child(3) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.25s;
}

.section-team .custom-list-team .box-item-team:hover .info li:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.15s;
}

.section-team .custom-list-team .box-item-team:hover .info li:first-child {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}