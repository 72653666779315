/**
  * Name: Hamela
  * Version: 1.0.1
  * Author: Themesflat
  * Author URI: http://www.themesflat.com
*/

$colorprimary: #11161e;
$colorsecondary : #fff;
$colorhover : #0446F1;
$colortext: #777777;
$bg-section : #f3f3f4;

// $colorprimary: #11161e;
// $colorsecondary : #fff;
// $colorhover : #ffa800;
// $colortext: #777777;
// $bg-section : #f3f3f4;

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('ionicons.css');

/*#region Font Herders*/

@font-face {
    font-family: 'codec_pro';
    src: url("../fonts/Codec_Pro/CodecPro-ExtraBold.ttf");
    font-weight: bold;
 }
 
 
 /*For Subheaders And Paragraphs */
 
 @font-face { 
     font-family:'garet';
     src:url('../fonts/Codec_Pro/Garet\ Regular.ttf')
 }
 /*#endregion*/


/**
    *   Reset Browsers
    *   General
    *   Elements
    *   List
    *   Table
    *   Media
    *   Typography
    *   Header
    *   Slider
    *   About
    *   Work
    *   Portfolio
    *   Counter
    *   Video
    *   Testimonials
    *   Facts
    *   Pricing
    *   Blog
    *   Partner
    *   Team
    *   Page Title
    *   Project Detail
    *   Blog Detail
    *   Blog Arcire
    *   Contact
    *   Footer
    *   Scroll Top
*/

/* Reset Browsers
-------------------------------------------------------------- */

html,
body,
header,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

html {
    font-size: 62.5%;
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    background: #fff;
    line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section {
    display: block
}

ol,
ul {
    list-style: none
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

caption,
th,
td {
    font-weight: normal;
    text-align: left;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

blockquote,
q {
    quotes: none
}

a:focus {
    outline: none
}

a:hover,
a:active {
    outline: 0
}

a img {
    border: 0
}

img {
    max-width: 100%;
    height: auto;
}

select {
    max-width: 100%
}


/* General
-------------------------------------------------------------- */
body,
button,
input,
select,
textarea {
    font-family: 'codec_pro','Oswald', sans-serif;
    font-weight: 400;
    color: $colorprimary;
    font-size: 14px;
    line-height: 24px;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    overflow-y: auto;
}

img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    -ms-interpolation-mode: bicubic
}

strong,
b,
cite {
    font-weight: bold;
}

dfn,
cite,
em,
i,
blockquote {
    font-style: italic;
}

blockquote {
    position: relative;
    background-color: transparent;
    margin: 0px;
    padding: 0 0 0 32px;
}

blockquote>p:last-child {
    margin-bottom: 0;
}

blockquote cite {
    position: absolute;
    right: 0;
    bottom: 0;
}

blockquote em,
blockquote i {
    font-style: normal;
}

abbr,
acronym {
    border-bottom: 1px dotted #e0e0e0;
    cursor: help;
}

mark,
ins {
    text-decoration: none;
}

sup,
sub {
    font-size: 75%;
    height: 0;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -6px;
}

sub {
    bottom: -3px;
}

small {
    font-size: 75%;
}

big {
    font-size: 125%;
}

address {
    font-style: italic;
    margin: 0 0 20px;
}

code,
kbd,
tt,
var,
samp,
pre {
    margin: 20px 0;
    padding: 4px 12px;
    background: #f5f5f5;
    border: 1px solid #e0e0e0;
    overflow-x: auto;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
    border-radius: 0;
    height: auto;
}

/* Elements
-------------------------------------------------------------- */
html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

hr {
    background-color: rgba(17, 22, 30, 0.3);
    border: 0;
    height: 1px;
    margin-bottom: 20px;
}

/* List */
ul,
ol {
    padding: 0;
    margin: 0 0 20px 20px;
}

ul {
    list-style: disc;
}

ol {
    list-style: decimal;
}

li>ul,
li>ol {
    margin-bottom: 0;
}

li {
    list-style: none;
}

ul li,
ol li {
    padding: 0.1em 0;
}

dl,
dd {
    margin: 0 0 20px;
}

dt {
    font-weight: bold;
}

del,
.disable {
    text-decoration: line-through;
    filter: alpha(opacity=50);
    opacity: 0.5;
}

/* Table */
table,
th,
td {
    border: 1px solid #e0e0e0;
}

table {
    border-collapse: separate;
    border-spacing: 0;
    border-width: 1px 0 0 1px;
    margin: 0 0 30px;
    table-layout: fixed;
    width: 100%;
}

caption,
th,
td {
    font-weight: normal;
    text-align: left;
}

th {
    border-width: 0 1px 1px 0;
    font-weight: bold;
}

td {
    border-width: 0 1px 1px 0;
}

th,
td {
    padding: 8px 12px;
}

a:hover {
    text-decoration: none;
}

/* Media */
embed,
iframe,
object,
video {
    margin-bottom: 20px;
    max-width: 100%;
    vertical-align: middle;
}

p>embed,
p>iframe,
p>object,
p>video {
    margin-bottom: 0;
}

p {
    font-family: 'Open Sans', sans-serif;
}

/* Links */
a {
    color: $colorsecondary;
    outline: 0;
    text-decoration: none;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

a:focus,
a:active {
    outline: 0;
    text-decoration: none
}

/* Typography
-------------------------------------------------------------- */
h1 {
    font-family: 'codec_pro','Oswald', sans-serif;
    color: $colorprimary;
    font-weight: 400;
    line-height: 1.5;
    text-rendering: optimizeLegibility;
}

h2,
h3,
h4,
h5,
h6 {
    font-family: 'garet','Oswald', sans-serif;
    color: $colorprimary;
    font-weight: 400;
    line-height: 1.5;
    text-rendering: optimizeLegibility;
}

h1 {
    font-size: 102px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 128px;
}

h2 {
    font-size: 72px;
}

h3 {
    font-size: 32px;
    font-weight: 400;
    line-height: 1.313em;
}

h4 {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.2em;
    letter-spacing: 10.8px;
    color: $colorhover;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit
}
p{
    font-family: 'garet','Oswald', sans-serif;

}



// Header **********
.header {
    position: relative;
    z-index: 9999;

    &::before {
        position: absolute;
        left: 0;
        bottom: -2px;
        content: '';
        height: 2px;
        width: 100%;
        background: rgba(255, 255, 255, 0.102);
    }

    img.img-top-page {
        position: absolute;
        z-index: 99;
        top: 0;
        left: 0;
    }

    /* Mobile Menu */
    #main-nav-mobi {
        display: block;
        margin: 0 auto;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 9999;
        background-color: #f6f6f6;

        ul {
            display: block;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin: 0;
                text-align: left;
                cursor: pointer;
                padding-left: 16px;
                border-top: 1px solid #ebebeb;
                position: relative;

                a {
                    color: #999;
                    display: inline-block;
                    font-size: 14px;
                    text-transform: uppercase;
                    line-height: 48px;
                    font-weight: 600;

                    &:hover {
                        color: $colorhover;
                    }
                }

                &:first-child {
                    border-top: 0px;
                }
            }

        }

        .menu-item-has-children {
            .arrow {
                cursor: pointer;
                display: inline-block;
                font-size: 20px;
                font-family: "FontAwesome";
                line-height: 48px;

                position: absolute;
                right: 3px;
                text-align: center;
                top: 0;
                width: 48px;

                &:before {
                    content: "\f103";
                    color: #b1b1b1;
                }
            }

            .arrow.active:before {
                content: "\f102";
            }
        }

        ul ul li {
            background-color: #f2f2f2;
            border-color: #ebebeb;
        }
    }

    .main-header {
        padding: 22px 0 28px 0;
        position: relative;

        .mobile-button {
            display: none;
            width: 26px;
            height: 26px;
            float: right;

            background-color: transparent;
            cursor: pointer;

            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;

            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);

            &:before,
            &:after,
            span {
                background-color: $colorhover;

                -webkit-transition: all ease 0.3s;
                -moz-transition: all ease 0.3s;
                transition: all ease 0.3s;
            }

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 0;
                height: 3px;
                width: 100%;
                left: 0;
                top: 50%;
                -webkit-transform-origin: 50% 50%;
                -ms-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
            }

            span {
                position: absolute;
                width: 100%;
                height: 3px;
                left: 0;
                top: 50%;
                overflow: hidden;
                text-indent: 200%;
            }

            &:before {
                -webkit-transform: translate3d(0, -7px, 0);
                -moz-transform: translate3d(0, -7px, 0);
                transform: translate3d(0, -7px, 0);
            }

            &:after {
                -webkit-transform: translate3d(0, 7px, 0);
                -moz-transform: translate3d(0, 7px, 0);
                transform: translate3d(0, 7px, 0);
            }
        }

        .mobile-button.active {
            span {
                opacity: 0;
            }

            &:before {
                -webkit-transform: rotate3d(0, 0, 1, 45deg);
                -moz-transform: rotate3d(0, 0, 1, 45deg);
                transform: rotate3d(0, 0, 1, 45deg);
            }

            &:after {
                -webkit-transform: rotate3d(0, 0, 1, -45deg);
                -moz-transform: rotate3d(0, 0, 1, -45deg);
                transform: rotate3d(0, 0, 1, -45deg);
            }
        }

        .main-header-logo {
            padding-left: 162px;
            // padding-top: 18px;
        }

        .main-nav {
            position: absolute;
            left: 24.9%;
            top: 50px;

            .menu {
                margin: 0;

                li {
                    a.menu-main:active {
                        color: $colorhover;
                    }
                }

                li.menu-item-has-children {
                    position: relative;
                    display: block;
                    float: left;
                    padding-right: 39px;
                    height: 60px;

                    a.menu-main {
                        position: relative;
                        text-decoration: none;
                        color: #fff;

                        font-size: 16px;
                        font-weight: 500;
                        text-transform: uppercase;

                        &:active {
                            color: $colorhover;
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            bottom: -9px;
                            left: 0px;
                            width: 0%;
                            height: 3px;
                            background-color: $colorhover;
                            opacity: 1;
                            visibility: hidden;
                            -webkit-transition: all 0.3s ease;
                            -moz-transition: all 0.3s ease;
                            -ms-transition: all 0.3s ease;
                            -o-transition: all 0.3s ease;
                            transition: all 0.3s ease;
                        }

                        &:hover {
                            color: $colorhover;
                        }

                        &:hover:after {
                            opacity: 1;
                            visibility: visible;
                            width: 100%;
                        }
                    }

                    a.menu-main.active {
                        &:after {
                            opacity: 1;
                            visibility: visible;
                            width: 100%;
                        }
                    }

                }
            }

            /* Sub Menu */
            .sub-menu {
                position: absolute;
                top: 44px;
                left: -20px;
                width: 250px;
                padding: 15px 0px;
                z-index: 9999;

                display: block !important;

                background-color: $colorsecondary;
                opacity: 0;
                visibility: hidden;

                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transition: all 0.3s ease;
                -webkit-transform: translateY(15px);
                -moz-transform: translateY(15px);
                -ms-transform: translateY(15px);
                -o-transform: translateY(15px);
                transform: translateY(15px);

                li {
                    display: block;

                    a {
                        position: relative;
                        display: block;
                        font-size: 16px;
                        color: $colorprimary;
                        padding: 5px 20px;
                        font-weight: 500;
                        text-transform: uppercase;
                    }
                }

                .right-sub-menu {
                    left: auto;
                    right: 0;
                }

                .sub-menu-2 {
                    position: absolute;
                    top: 0;
                    left: 234px;
                    width: 250px;
                    padding: 15px 0px;
                    z-index: 9999;

                    background-color: $colorsecondary;
                    opacity: 0;
                    visibility: hidden;

                    -webkit-transition: all 0.3s ease;
                    -moz-transition: all 0.3s ease;
                    -ms-transition: all 0.3s ease;
                    -o-transition: all 0.3s ease;
                    transition: all 0.3s ease;
                    -webkit-transform: translateY(15px);
                    -moz-transform: translateY(15px);
                    -ms-transform: translateY(15px);
                    -o-transform: translateY(15px);
                    transform: translateY(15px);

                    li {
                        display: block;

                        a {
                            position: relative;
                            display: block;
                            font-size: 14px;
                            color: $colorprimary;
                            padding: 5px 20px;
                            font-weight: 600;
                        }
                    }

                    .right-sub-menu {
                        left: auto;
                        right: 0;
                    }
                }

                li:hover .sub-menu-2 {
                    opacity: 1;
                    visibility: visible;
                    -webkit-transform: translateY(0);
                    -moz-transform: translateY(0);
                    -ms-transform: translateY(0);
                    -o-transform: translateY(0);
                    transform: translateY(0);
                }
            }


            li:hover .sub-menu {
                opacity: 1;
                visibility: visible;
                -webkit-transform: translateY(0);
                -moz-transform: translateY(0);
                -ms-transform: translateY(0);
                -o-transform: translateY(0);
                transform: translateY(0);
            }

            .sub-menu li a:hover,
            #main-nav .sub-menu li.current-item a {
                color: $colorhover;
            }
        }

        .main-nav.style-2 {
            .menu {
                li.menu-item-has-children {
                    a.menu-main {
                        color: $colorprimary ;

                        &:hover {
                            color: $colorhover ;
                        }
                    }
                }
            }

            .sub-menu {
                background: $colorprimary ;

                li {
                    a {
                        color: $colorsecondary ;

                        &:hover {
                            color: $colorhover ;
                        }
                    }
                }

                .sub-menu-2 {
                    background: $colorprimary  !important;

                    li {
                        a {
                            color: $colorsecondary  !important;

                            &:hover {
                                color: $colorhover  !important;
                            }
                        }
                    }
                }
            }
        }

        .main-header-contact {
            padding-right: 146px;

            .text-phone {
                color: #fff;
                float: left;
                padding-right: 30px;

                a {
                    &:hover {
                        color: $colorhover;
                    }
                }

                p {
                    font-family: 'Open Sans', sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: end;
                    padding-top: 3px;
                    padding-bottom: 18px;
                }

                span {
                    display: block;
                    font-size: 24px;
                    font-weight: bold;
                    margin-top: -11px;
                }
            }

            .call {
                position: relative;
                background: $colorhover;
                padding: 24px 67px 65.5px 42px;

                &:hover {
                    background: $colorprimary;
                }

                img {
                    position: absolute;
                    top: 31%;
                    left: 35%;
                }
            }
        }
    }

    // Header menu Home 2
    .main-header.style-2 {
        .main-header-logo {
            padding-left: 0;
            padding-top: 12px;
        }

        .main-nav {
            position: inherit;
            padding-top: 24px;
            padding-left: 4px;
        }

        .main-header-contact {
            padding-right: 0;
            float: right;
        }
    }
}

.header.b2 {
    &::before {
        position: absolute;
        left: 0;
        bottom: -2px;
        content: '';
        height: 2px;
        width: 100%;
        background: rgba(17, 22, 30, 0.102);
    }
}

.header.style-2 {
    &::before {
        display: none;
    }
}

.menu-item a.active {
    color: $colorhover  !important;
}

// Header Home 3
.header.style-2 {
    border: none;
    padding-left: 16px;

    hr.divider-header {
        margin-top: 2px;
        background: rgba(255, 255, 255, 0.102);
        height: 2px;
    }
}

// End Header

// Slider
.section-slider {
    position: relative;
    margin-top: -110px;
    overflow: hidden;
    height: 950px !important;

    .tp-parallax-wrap {
        z-index: 99 !important;
    }

    .rev_slider_wrapper.fullwidthbanner-container {
        height: 950px !important;
    }

    div#rev-slider1 {
        max-height: 950px !important;
        height: 950px !important;
    }

    .title-slide-2 {
        padding-top: 118px !important;
    }

    .style-2 {
        color: $colorprimary  !important;
    }

    .tp-mask-wrap {
        padding-top: 130px;
        margin-left: -31px;
    }

    img.before-slide {
        position: absolute;
        z-index: 99;
        bottom: 0;
        left: 19.4%;
    }

    img.after-slide {
        position: absolute;
        z-index: 99;
        top: 20%;
        right: 0;
    }

    .menu-slide {
        position: absolute;
        bottom: 41px;
        right: 150px;

        .list-menu-slide {
            margin: 0;

            &::before {
                content: '';
                position: absolute;
                z-index: 9;
                top: -91px;
                right: 2px;

                width: 22px;
                height: 23px;
                background: $colorhover;
                border-radius: 50%;

                -webkit-animation: zoom-fade-two 5s infinite linear;
                animation: zoom-fade-two 5s infinite linear;

            }

            li {
                display: inline-block;
                padding-left: 30px;

                img {
                    margin-top: -8px;
                }

                a {
                    font-size: 18px;
                    font-weight: 500;
                    color: $colorsecondary;

                    &:hover {
                        color: $colorhover;
                    }
                }
            }
        }
    }

    .tp-mask-wrap {
        overflow: inherit !important;
    }

    .tp-caption.tp-resizeme.text-white.font-heading.font-weight-600 {
        letter-spacing: 10.7px !important;
        color: $colorhover  !important;
    }

    .tp-caption.tp-resizeme.text-white.font-heading.font-weight-700 {
        font-weight: bold;
        margin-top: 14px !important;
    }

    .tp-caption.padding-top-100.padding-left-10.text-slide {
        font-size: 16px;
        font-family: 'Open Sans', sans-serif;
        color: #b0b0b0 !important;
    }

    .tp-caption.tp-resizeme.text-white.font-heading.font-weight-700.padding-top-100.text-slide {
        color: #b0b0b0 !important;
        margin-top: 267px !important;
        margin-left: 6px !important;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400 !important;
    }

    .btn-slider {
        padding-top: 125px !important;
        padding-left: 10px !important;
    }

}

.section-slider.style-2 {
    .tp-bullet {
        background: rgba(17, 22, 30, 0.102);
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
    }

    .tp-bullet:hover {
        background: $colorhover;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
    }

    .tp-bullet.selected {
        background: $colorhover;
    }
}

.section-slider.style-3 {
    margin-top: -158px;

    .tp-mask-wrap {
        padding-top: 130px;
        margin-left: -31px;
    }
}


.tp-bullets.custom.horizontal.nav-pos-hor-center.nav-pos-ver-bottom.nav-dir-horizontal {
    visibility: inherit !important;
    opacity: 1 !important;
    position: absolute;
    top: 91.4% !important;
    left: 15.9% !important;
}

.section-slider.style-2 .tp-bullets.custom.horizontal.nav-pos-hor-center.nav-pos-ver-bottom.nav-dir-horizontal {
    left: 12.3% !important;
}

.tp-bullet {
    width: 60px;
    height: 2px;
    position: relative;
    background: rgba(255, 255, 255, 0.102);

    cursor: pointer;
    left: 0 !important;
    top: 0;
    margin-bottom: 20px;

    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.tp-bullet.selected,
.tp-bullet:hover {
    width: 100px;
    height: 2px;
    background: $colorhover;

    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

// End Slide

// About **********

.section-about {

    .intro-content {
        padding-top: 116px;
        position: relative;

        h4.sub-title {
            text-transform: uppercase;
            color: $colorhover;
            margin: 0 0 5px;
        }

        h3.title {
            position: relative;
            z-index: 99;
            display: inline-block;
            text-transform: uppercase;

            color: $colorprimary;

            &::before {
                content: '';
                position: absolute;
                z-index: 9;
                top: 0;
                right: -9px;

                width: 25px;
                height: 25px;
                background: $colorhover;
                border-radius: 50%;

            }

            span {
                position: relative;
                z-index: 99;
            }



        }

        h5.description {
            font-size: 18px;
            font-weight: 400;
            letter-spacing: -0.05px;
            line-height: 1.667em;
            padding-top: 36px;

            color: $colorprimary;
        }

        p.text {
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.75em;
            color: #777777;

            padding-right: 75px;
            padding-top: 16px;
            padding-bottom: 24px;

            color: $colortext;
        }
    }

    .image-about {
        text-align: end;
    }

    .image-about.style-2 {
        text-align: start;
    }

    .image-about.style-3 {
        img {
            width: 100%;
        }
    }
}

.section-about.style-2 {
    background: $bg-section;
    padding-bottom: 0;

    .intro-content {
        padding-left: 9px;
    }

    hr {
        margin-top: 120px;
        padding: 0;
        margin-bottom: 0;
    }
}

.section-about.s2 {
    padding-bottom: 105px;

    .image-about {
        img {
            width: auto;
        }
    }
}

.section-about.style-3 {
    padding-bottom: 116px;
}

// End About


// Section Work

.section-work {
    background: #f3f3f4;

    .list-box-work {
        display: flex;

        .box-work {
            width: 25%;
            background: $colorsecondary;
            position: relative;
            overflow: hidden;

            padding: 50px 20px 73px 30px;
            margin-right: 30px;

            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -ms-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            transition: all 0.5s ease;

            &:hover {
                box-shadow: 0px 24px 60px 0px rgba(0, 0, 0, 0.1);

                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -ms-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                transition: all 0.5s ease;
            }

            &:hover span {
                color: $colorhover;

                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -ms-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                transition: all 0.5s ease;
            }

            span {
                font-size: 40px;
                padding-bottom: 26px;
                display: block;

                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -ms-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                transition: all 0.5s ease;
            }

            &:after {
                content: '';
                width: 14%;
                height: 19%;

                background: url('./../images/bg-after-box.png') no-repeat;
                position: absolute;
                top: 0;
                left: -10px;
                overflow: hidden;
                opacity: 0;

                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -ms-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                transition: all 0.5s ease;
            }

            &:hover:after {
                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -ms-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                transition: all 0.5s ease;
                left: 0;
                opacity: 1;
            }

            .title-box {
                position: relative;
                padding-bottom: 20px;

                &:before {
                    content: '';
                    position: absolute;
                    top: -4px;
                    left: -5px;

                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background: #ffa800;

                }
            }

            .title-box a {
                position: relative;
                z-index: 99;

                color: $colorprimary;
                font-size: 16px;
                font-weight: 500;
                text-decoration: none;

                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -ms-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                transition: all 0.5s ease;

                &:hover {
                    color: $colorhover;
                    padding-left: 10px;
                    -webkit-transition: all 0.5s ease;
                    -moz-transition: all 0.5s ease;
                    -ms-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                    transition: all 0.5s ease;
                }
            }

            .text {
                color: $colortext;
                font-size: 14px;
                font-weight: 400;
                padding-bottom: 46px;
            }

            .readmore {
                padding: 6px 12px;
                border-radius: 30px;
                background: #f3f3f4;

                &:hover {
                    background: $colorhover;
                }

                i {
                    color: $colortext;
                }
            }
        }

        .box-work:nth-child(3) {
            padding: 50px 54px 73px 54px;
        }

        .box-work:nth-child(4) {
            margin-right: 0px;
            padding: 50px 54px 73px 54px;
        }
    }




}

.section-work.style-2 {
    background: url('../images/portfolio/bg-portfolio.jpg');

    .heading-top {
        h3.title {
            color: $colorsecondary;

            line-height: 1.5em;
            padding-top: 4px;
            letter-spacing: 0.15px;
        }
    }

    .description-right {
        padding-left: 10px;

        h6.title {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.05px;
            line-height: 1.7em;

            padding-top: 32px;
            padding-bottom: 26px;

            color: $colorsecondary;
        }
    }

    hr#divider {
        height: 1px;
        background: #fff;
        width: 1170px;
        margin-left: 8px;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .divider-left {
        position: relative;
        width: 5px;
        height: 100%;
        float: left;

        &:before {
            content: '';
            width: 100%;
            height: 30px;
            background: rgb(255, 168, 0);

            position: absolute;
            z-index: 99;
            top: -30px;
            left: 0;
        }

        &:after {
            position: absolute;
            top: 0;
            left: 0;

            content: '';
            width: 100%;
            height: 83px;
            background: rgba(255, 255, 255, 0.102);
        }
    }

    .list-box-work {
        display: flex;

        .box-work {
            background: transparent;
            padding: 0;
            overflow: inherit;
            width: 23%;
            margin-right: 30px;

            &:after {
                display: none;
            }

            &:hover .box-item span {
                color: $colorhover;
            }

            .box-item {
                margin-left: 30px;

                span {
                    color: $colortext;
                }

                .title-box {
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        top: -4px;
                        left: -5px;

                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background: #ffa800;

                    }

                    a {
                        position: relative;
                        z-index: 99;
                        color: $colorsecondary;
                        font-size: 16px;
                        -webkit-transition: all 0.5s ease;
                        -moz-transition: all 0.5s ease;
                        -ms-transition: all 0.5s ease;
                        -o-transition: all 0.5s ease;
                        transition: all 0.5s ease;

                        &:hover {
                            color: $colorhover;
                            padding-left: 10px;
                            -webkit-transition: all 0.5s ease;
                            -moz-transition: all 0.5s ease;
                            -ms-transition: all 0.5s ease;
                            -o-transition: all 0.5s ease;
                            transition: all 0.5s ease;
                        }
                    }

                }

                p.text {
                    color: $colortext;
                    padding-right: 10px;
                }

                .readmore {
                    padding: 6px 12px;
                    border-radius: 30px;
                    background: rgba(243, 243, 244, 0.102);

                    i {
                        color: $colortext;
                    }

                    &:hover {
                        background: $colorhover;

                        i {
                            color: $colorsecondary;
                        }
                    }
                }
            }
        }

        .box-work:nth-child(4) {
            margin-right: 0;
        }

        .box-work:nth-child(5) {
            margin-top: 83px;
        }

        .box-work:nth-child(6) {
            margin-top: 83px;
        }

        .form-portfolio {
            border: 5px solid $colorhover;

            padding: 39px 95px 0px 95px;
            margin-top: -30px;
            margin-left: 2px;
            width: 48.7%;

            .heading-form {
                img {
                    float: left;
                    padding-right: 28px;
                }

                p {
                    font-size: 18px;
                    font-weight: 300;
                    letter-spacing: 11px;
                    line-height: 1.1em;
                    color: #ffa800;
                    padding-top: 7px;
                }

                h4 {
                    font-size: 24px;
                    font-weight: 300;
                    line-height: 1.5em;
                    color: #fff;
                    text-transform: uppercase;
                    font-weight: 500;
                    letter-spacing: 0;
                }
            }

            .fomr-contact {
                padding-top: 58px;
                padding-bottom: 21px;

                .form-group {
                    position: relative;

                    input#email {
                        background: rgba(255, 255, 255, 0.051);
                        color: $colortext;
                        padding: 23px 29px;
                        font-size: 13px;
                        font-family: 'Open Sans', sans-serif;
                        border: none;
                        width: 100%;
                    }

                    .btn {
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        width: 21.5%;
                        border-radius: 0;
                        background: $colorhover;

                        &::before {
                            content: '\f1d8';
                            font-family: 'Font Awesome 5 Free';
                            font-size: 20px;
                        }

                        &:hover {
                            background: $colorsecondary;
                        }
                    }
                }
            }
        }
    }



}

.section-work.style-2.style-3 {
    background: $bg-section;
    padding-bottom: 125px;

    .heading-top {
        h4.sub-title {
            font-size: 18px;
            font-weight: 300;
            letter-spacing: 11.5px;
            line-height: 1.3em;
            color: #ffa800;
        }

        h3.title {
            color: $colorprimary;
        }
    }

    .description-right {
        padding-top: 4px;

        h6.title {
            color: $colorprimary;
            padding-top: 27px;
        }
    }

    hr#divider {
        background: rgba(17, 22, 30, 0.102);
        margin-bottom: 27px;
    }

    .list-box-work {
        .box-work {
            &:hover {
                box-shadow: none;
            }

            .box-item {
                .title-box a {
                    color: $colorprimary;

                    &:hover {
                        color: $colorhover;
                    }
                }

                .readmore {
                    background: rgba(17, 22, 30, 0.102);

                    &:hover {
                        background: $colorhover;
                    }
                }

            }

            .divider-left:after {
                background: rgba(17, 22, 30, 0.102);

            }
        }
    }

}

.section-work.style-2.style-3.s2 {
    padding-bottom: 127px;
}

.section-work.style-2.style-3.s1 {
    padding-top: 150px;
    padding-bottom: 126px;
}

// End Work

// Portfolio

.section-portfolio {
    background: #f3f3f4;

    .heading-top {
        .sub-title {


            color: $colorhover;
        }

        .title {
            font-size: 32px;
            font-weight: 400;
            line-height: 1.5em;
            color: #11161e;

            text-transform: none;

            padding-top: 4px;
            letter-spacing: 0.15px;

            color: $colorprimary;
        }
    }

    .description-right {
        padding-left: 10px;

        h6.title {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.05px;
            line-height: 1.7em;

            padding-top: 32px;
            padding-bottom: 26px;

            color: $colorprimary;
        }
    }

    .list-portfolio {
        display: flex;
        padding: 0 54px 80px 54px;

        .themesflat-portfolio {
            margin-right: 30px;

            .boxitem {
                position: relative;
                z-index: 99;

                &:hover .imgbox img.light {
                    opacity: 1;
                    visibility: visible;

                    -webkit-transition: all 0.5s ease;
                    -moz-transition: all 0.5s ease;
                    -ms-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                    transition: all 0.5s ease;
                }

                .imgbox {
                    height: 100%;
                    position: relative;

                    img.light {
                        position: absolute;
                        top: 0;
                        left: 0;

                        opacity: 0;
                        visibility: hidden;
                        -webkit-transition: all 0.5s ease;
                        -moz-transition: all 0.5s ease;
                        -ms-transition: all 0.5s ease;
                        -o-transition: all 0.5s ease;
                        transition: all 0.5s ease;
                    }
                }

                .box-item-content {
                    position: absolute;
                    z-index: 9;
                    bottom: 20px;
                    left: 0;
                    width: 100%;
                    padding: 0 20px;

                    .content-box {
                        background: #fff;
                        color: #11161e;

                        border-radius: 3px;
                        width: 100%;
                        height: auto;
                        padding: 25px 30px;

                        position: relative;
                        z-index: 99;

                        -webkit-transition: all 0.5s ease;
                        -moz-transition: all 0.5s ease;
                        -ms-transition: all 0.5s ease;
                        -o-transition: all 0.5s ease;
                        transition: all 0.5s ease;

                        &:hover {
                            background: $colorprimary;
                            -webkit-transition: all 0.5s ease;
                            -moz-transition: all 0.5s ease;
                            -ms-transition: all 0.5s ease;
                            -o-transition: all 0.5s ease;
                            transition: all 0.5s ease;
                        }

                        &:hover a {
                            color: $colorsecondary;

                            -webkit-transition: all 0.5s ease;
                            -moz-transition: all 0.5s ease;
                            -ms-transition: all 0.5s ease;
                            -o-transition: all 0.5s ease;
                            transition: all 0.5s ease;

                        }



                        &:after {
                            content: '';
                            width: 37px;
                            height: 66%;

                            background: url('./../images/bg-after-box1.png') no-repeat;
                            position: absolute;
                            top: 0;
                            right: -0;
                        }

                        h6.subtitle {
                            color: $colortext;
                            font-size: 14px;
                            font-weight: 400;
                            text-transform: uppercase;
                            margin-bottom: 3px;
                            letter-spacing: 0.1px;
                            padding-left: 2px;
                        }

                        h5.title {
                            font-size: 18px;
                            font-weight: bold;
                            text-transform: uppercase;
                            position: relative;
                            z-index: 99;
                            line-height: 1.667em;
                            letter-spacing: -0.2px;

                            a {
                                position: relative;
                                z-index: 99;
                            }

                            &:before {
                                content: '';
                                position: absolute;
                                z-index: 9;
                                top: 3px;
                                left: -7px;

                                width: 20px;
                                height: 20px;
                                border-radius: 50%;
                                background: #ffa800;

                            }
                        }
                    }
                }


            }
        }

        .themesflat-portfolio:nth-child(5) {
            margin-right: 0;
        }

    }

    .view-portfolio {
        text-align: center;
        padding-bottom: 11px;

        .dark {
            margin-left: 4px;

            a {
                background: #11161e;
                color: #fff;
                padding: 21px 30px 21px 50px;
            }
        }
    }

    .view-portfolio.style-2 {
        padding-top: 10px;
    }
}

.section-portfolio.style-2 {
    padding-top: 119px;
    padding-left: 30px;

    .description-right {
        padding-left: 3px;
    }

    .list-portfolio {
        display: flex;
        padding: 0;

        .themesflat-portfolio {
            margin-right: 30px;
        }

        .themesflat-portfolio:nth-child(3) {
            margin-right: 0;
        }

        .themesflat-portfolio:nth-child(6) {
            margin-right: 0;
            margin-top: 50px;
        }

        .imgbox {
            img {
                height: auto;
                width: 100%;
            }
        }

        .boxitem {
            position: relative;
            z-index: 99;
        }
    }

    .view-portfolio {
        padding-top: 79px;

        .dark {
            margin-left: -13px;
        }
    }
}

// End Portfolio


// counter
.section-counter {
    background: url('../images/background/bg_counter.jpg') no-repeat;
    background-size: cover;

    padding-top: 120px;
    padding-bottom: 365px !important;

    .heading-top {
        text-align: center;

        h4.sub-title {
            text-transform: none;
            margin-top: -2px;
            padding-left: 7px;

            color: $colorhover;
        }

        h3.title {
            font-size: 24px;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 1.3em;

            padding-bottom: 108px;
            color: $colorsecondary;
        }

        h3.style-2 {
            color: $colorprimary;
        }
    }

    .list-counter {
        display: flex;

        .themesflat-content-box {
            width: 25%;

            .themesflat-counter.v1 {
                margin-left: -5px;
            }

            .themesflat-counter.v2 {
                margin-left: 3px;
            }

            .themesflat-counter.v3 {
                margin-left: 10px;
            }

            .themesflat-counter.v4 {
                margin-left: 18px;
            }


            .counter-item {
                padding: 0 23px;

                .number-wrap {
                    position: relative;

                    &:before {
                        position: absolute;
                        z-index: 9;
                        top: -44px;
                        left: -19px;
                        content: '';
                        width: 70px;
                        height: 70px;
                        border: 5px solid rgba(255, 255, 255, 0.102);
                        border-radius: 50%;

                        -webkit-transition: all 0.3s ease;
                        -moz-transition: all 0.3s ease;
                        -ms-transition: all 0.3s ease;
                        -o-transition: all 0.3s ease;
                        transition: all 0.3s ease;
                    }

                    &:hover:before {
                        border-color: $colorhover;

                        -webkit-transition: all 0.3s ease;
                        -moz-transition: all 0.3s ease;
                        -ms-transition: all 0.3s ease;
                        -o-transition: all 0.3s ease;
                        transition: all 0.3s ease;
                    }

                    .number {
                        position: relative;
                        z-index: 99;
                        color: $colorsecondary;
                        font-size: 60px;
                        font-weight: 500;
                        float: left;

                        padding-right: 5px;
                    }
                }

                .heading {
                    font-family: 'Open Sans', sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba(255, 255, 255, 0.7);
                    display: inline-block;
                    margin-top: 8px;
                    text-transform: none;
                }

                .number-wrap.active {
                    &:before {
                        border-color: $colorhover;
                    }
                }

            }
        }

    }


}

.section-counter.style-2 {
    background: $bg-section;
    padding-bottom: 113px !important;

    .list-counter .themesflat-content-box .counter-item .heading {
        color: $colorprimary;
        font-weight: 500;
    }

    .heading-top {
        h3.title {
            color: $colorprimary;
        }
    }

    .counter-item {
        .text-wrap {
            .number-wrap {
                .number {
                    color: $colorprimary;
                }

                &:before {
                    border-color: rgba(17, 22, 30, 0.102);
                }
            }
        }
    }
}

// end Counter

// Section video

.section-video {
    background: #f3f3f4;

}

.section-video.style-2 {
    .box-video {
        margin-top: 0;
    }
}

// End Section Video

// Section testimonials
.section-testimonials {
    background: #f3f3f4;

    .heading-top {
        text-align: center;

        h4.sub-title {
            text-transform: none;
            color: $colorhover;
            padding-left: 13px;
            padding-bottom: 20px;
        }

        h3.title {
            font-size: 24px;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 1.3em;

            color: $colorprimary;
            padding-bottom: 65px;
        }
    }
}

.section-testimonials.style-2 {
    background: $colorsecondary;

    .heading-top {
        text-align: left;

        h4.sub-title {
            font-weight: 300;
            letter-spacing: 11px;
            line-height: 1em;
            padding-left: 0;
            color: #ffa800;
            padding-bottom: 9px;
        }

        h3.title {
            font-size: 32px;
            text-transform: inherit;
            line-height: 1.5em;
            padding-bottom: 60px;
        }
    }

    .description-right {
        padding-top: 32px;
        padding-left: 12px;

        h6.title {
            padding-bottom: 28px;
        }
    }

    .testimonials-item {
        background: rgb(243, 243, 244);
    }

    .has-bullets.bullet24 .owl-theme .owl-dots {
        display: none;
    }
}

.section-testimonials.style-3 {
    padding-top: 0 !important;

    .testimonials-item {
        background: url('../images/background/bg-testimonial-03.jpg');

        .thumb {
            padding: 70px 170px 63px 100px;

            h3.text {
                color: $colorsecondary;
                padding-right: 30px;
            }

            h4.author {
                color: $colorsecondary;
                padding-left: 0px;

                &::before {
                    left: -10px;
                }
            }

            p.category {
                padding-left: 0px;
            }
        }
    }

    .has-bullets.bullet24 .owl-theme .owl-dots {
        position: absolute;
        z-index: 99;
        top: 41.5%;
        right: -53px;
        transform: rotate(90deg);
    }

    .owl-theme .owl-dot span {
        background: rgba(255, 255, 255, 0.102);
    }

    .owl-dots .owl-dot.active span {
        background: $colorhover;
    }
}

// End Section Testimonials

// Section Facts
.section-facts {
    position: relative;
    z-index: 99;
    background: url('../images/background/bg-fact-1.jpg') no-repeat;
    background-size: cover;
    background-position: center center;

    .facts-content {
        text-align: center;
        padding-top: 30px;

        h2.heading {
            font-size: 48px;
            line-height: 1.25em;
            color: $colorsecondary;
            text-transform: uppercase;
            text-decoration: underline;

            padding-top: 56px;
            padding-bottom: 32px;
            letter-spacing: -0.1px;

        }

        .btn-main {
            padding-bottom: 40px;
        }
    }
}

// End Section Facts
// Section Pricing

.section-pricing {
    background: #f3f3f4;

    .heading-top {
        .sub-title {
            text-transform: none;

            color: $colorhover;
            padding-bottom: 7px;
        }

        .title {
            text-transform: none;
            line-height: 1.46;

            color: $colorprimary;
        }
    }

    .description-right {
        padding-top: 8px;
        padding-left: 11px;

        h6.title {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 1.7em;

            padding-top: 24px;
            padding-bottom: 25px;
            color: $colorprimary;
        }
    }

    .list-pricing {
        display: flex;

        .themesflat-pricing {
            background: $colorsecondary;
            border-radius: 4px;
            width: 33.33%;
            margin-right: 30px;

            .pricing-item {
                text-align: center;
                position: relative;
                z-index: 99;

                .thumb {
                    padding: 70px 46px 80px 46px;

                    .sale-off {
                        position: absolute;
                        z-index: 9;
                        top: 11px;
                        left: 10px;
                        width: 60px;
                        height: 60px;

                        text-align: center;
                        padding: auto 0;

                        border-radius: 50%;
                        background: rgb(243, 243, 244);

                        p {
                                    font-size: 13px;
                            font-weight: bold;
                            line-height: 1.2em;
                            padding: 15px;
                        }
                    }

                    .price {
                        font-size: 42px;
                        font-weight: bold;
                        color: $colorhover;
                        line-height: 1em;
                        padding-bottom: 23px;
                    }

                    .package {
                        font-weight: 500;
                        text-transform: uppercase;
                    }

                    hr {
                        margin-top: 24px;
                        margin-bottom: 21px;
                    }

                    ul.list-table {
                        margin: 0;
                        padding-bottom: 21px;

                        li {
                            font-family: 'Open Sans', sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                            color: $colortext;

                            padding-top: 23px;
                        }
                    }

                    .btn-main {
                        padding-top: 50px;
                        padding-left: 5px;

                        a {
                            padding: 21px 25px 21px 50px;
                        }
                    }
                }
            }
        }

        .themesflat-pricing:nth-child(3) {
            margin-right: 0;
        }
    }

}

// End Section Pricing

// Section Blog
.section-blog {
    background: #f3f3f4;

    .heading-top {
        text-align: center;

        h4.sub-title {
            font-weight: 300;
            letter-spacing: 11px;
            line-height: 1em;
            padding-left: 10px;
            color: #ffa800;
            padding-bottom: 20px;
        }

        h3.title {
            font-size: 24px;
            font-weight: 400;
            line-height: 1.3em;
            color: #11161e;
            text-transform: uppercase;
            letter-spacing: -0.1px;
            padding-bottom: 65px;
        }
    }

    .list-blog {
        display: flex;

        .themesflat-blog {
            margin-right: 30px;
        }

        .themesflat-blog:nth-child(3) {
            margin-right: 0;
        }
    }
}

// End Section Blog

// Section partner
.section-partner {
    background: #f3f3f4;
    padding-top: 127px;
    padding-bottom: 132px;

    .thumb {
        width: 124px;
    }
}

.section-partner.style-2 {
    padding-top: 79px;
    padding-bottom: 133px;
}

.section-partner.style-3 {
    padding-top: 119px;
}

// End section Partner

// section Team 
.section-team {
    background: #f3f3f4;

    .heading-top {
        text-align: center;

        h4.sub-title {
            font-weight: 300;
            letter-spacing: 9.8px;
            line-height: 1.1em;

            color: $colorhover;
        }

        h3.title {
            font-size: 24px;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 1.3em;

            padding-bottom: 65px;
            color: $colorprimary;
        }
    }

    .list-team {
        display: flex;

        .box-item-team {
            position: relative;
            width: 25%;
            margin-right: 30px;

            &:nth-child(4) {
                margin-right: 0;
            }

            .imgbox {
                padding-bottom: 27px;
            }

            .info {
                position: absolute;
                z-index: 9;
                bottom: 82px;
                left: 20px;

                width: 230px;
                height: 70px;
                border-radius: 3px;
                background: $colorsecondary;
                opacity: 0;

                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -ms-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                transition: all 0.5s ease;

                &::after {
                    content: '';
                    background: url('../images/bg-social.png') no-repeat;
                    width: 36px;
                    height: 100%;

                    position: absolute;
                    top: 0;
                    right: 0;
                }

                .list-social {
                    display: flex;
                    margin-top: 15px;

                    li {
                        opacity: 0;
                        visibility: hidden;
                        -webkit-transform: translate3d(0, -50%, 0);
                        transform: translate3d(0, -50%, 0);
                        -webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
                        transition: opacity 0.2s,
                            transform 0.35s;

                        display: flex;
                        justify-content: center;

                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 10px;
                        background: rgba(17, 22, 30, 0.102);

                        &:hover {
                            background: rgb(255, 168, 0);

                            i {
                                &::before {
                                    color: $colorprimary;
                                }
                            }
                        }

                        i {
                            background: transparent;
                            padding-top: 30px;

                            &::before {
                                color: $colortext;
                            }
                        }

                        .fa-facebook-f:before,
                        .fa-facebook:before {
                            content: "\f39e";
                        }

                        a {
                            transform: translateY(-50%);
                        }
                    }
                }
            }

            h6.name {
                font-size: 18px;
                line-height: 1em;
                font-weight: 600;
                padding-bottom: 15px;
            }

            p.branch {
                font-family: 'Oswald', sans-serif;
                font-size: 14px;
                line-height: 1em;
                color: $colortext;
            }

            &:hover .info {
                bottom: 95px;
                opacity: 1;


                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -ms-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                transition: all 0.5s ease;


            }

            &:hover .info li {
                opacity: 1;
                visibility: visible;
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }

            &:hover .info li:nth-child(4) {
                -webkit-transition-delay: 0.20s;
                transition-delay: 0.35s;
            }

            &:hover .info li:nth-child(3) {
                -webkit-transition-delay: 0.15s;
                transition-delay: 0.25s;
            }

            &:hover .info li:nth-child(2) {
                -webkit-transition-delay: 0.1s;
                transition-delay: 0.15s;
            }

            &:hover .info li:first-child {
                -webkit-transition-delay: 0.05s;
                transition-delay: 0.05s;
            }
        }
    }

}

// End Section Team

// Page Title 
.page-title {
    background: url('../images/slider/slide-1.jpg') no-repeat;
    background-size: cover;
    background-position: top center;
    margin-top: -110px;
    padding: 215px 0 142px 0;

    .main-page {
        h2.title {
            position: relative;
            color: $colorsecondary;
            font-weight: bold;

            span {
                position: relative;
                z-index: 99;
            }

            &:before {
                content: '';
                position: absolute;
                z-index: 9;
                top: 60px;
                left: 45px;
                width: 22px;
                height: 22px;
                background: #ffa800;
                border-radius: 50%;
            }
        }

        .btn-main {
            padding-top: 59px;
            padding-left: 8px;
        }
    }
}

// end Page title
.section-about.service-detail {
    background: $bg-section;

    .intro-content {
        img.headphone {
            position: absolute;
            top: 70px;
            left: 0;
            -webkit-animation: zoom-fade-two 5s infinite linear;
            animation: zoom-fade-two 5s infinite linear;
        }

        h3.title {
            padding-top: 8px;

            &::before {
                top: 5px;
                right: -62px;
                width: 27px;
                height: 27px;
            }
        }

        h5.description {
            padding-top: 37px;
        }

        p.text-1 {
            padding-top: 18px;
            padding-right: 60px;
        }

        p.text-2 {
            padding-top: 24px;
            padding-right: 60px;
            padding-bottom: 23px;
        }
    }

    .image-about {
        img {
            width: 100%;
        }
    }
}

// section Project Detail
.section-project-details {
    background: $bg-section;
    padding-bottom: 130px;

    .main-project-details {
        display: flex;

        .project-content {
            width: 66%;
            margin-right: 30px;

            .themesflat-portfolio {
                padding-bottom: 42px;
            }

            p {
                font-size: 14px;
                color: rgb(119, 119, 119);
                line-height: 2.143;
            }

            .box-video {
                margin-top: -10px;
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 40px;

                .box {
                    position: relative;

                    background: url('../images/background/bg-video-detail.jpg') no-repeat;
                    background-position: center center;

                    padding: 170px 0;
                    background-color: rgb(204, 204, 204);
                    box-shadow: 0px 24px 60px 0px rgba(0, 0, 0, 0.05);
                    border: none;

                    .overlay {
                        background: #cccccc;
                        opacity: 0.6;
                        width: 100%;
                        height: 100%;

                        position: absolute;
                        z-index: 9;
                        top: 0;
                        left: 0;
                    }

                    .flat-video-fancybox {

                        width: 100%;
                        text-align: center;
                        top: 36%;

                        a.fancybox {
                            display: inline-block;
                            background: $colorhover;
                            width: 96px;
                            height: 96px;
                            border-radius: 50%;
                            position: relative;
                            z-index: 999;

                            span {
                                i {
                                    font-size: 28px;
                                    padding-top: 33%;
                                    color: $colorsecondary;
                                }
                            }

                            &::before {
                                content: '';
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                border: 3px solid $colorhover;
                                position: absolute;
                                top: 0;
                                left: 0;

                                -webkit-transition: all 0.5s ease;
                                -moz-transition: all 0.5s ease;
                                -ms-transition: all 0.5s ease;
                                -o-transition: all 0.5s ease;
                                transition: all 0.5s ease;
                            }
                        }

                        .overlay-inner {
                            i {
                                position: absolute;
                                top: 23%;
                                left: 45%;

                                color: $colortext;
                                font-size: 20px;
                                width: 96px;
                                height: 96px;
                                border-radius: 50%;
                                padding: 20px 40px;

                                line-height: 60px;
                                background: $colorhover;
                                -webkit-transition: all 0.3s ease-in-out;
                                -moz-transition: all 0.3s ease-in-out;
                                -ms-transition: all 0.3s ease-in-out;
                                -o-transition: all 0.3s ease-in-out;
                                transition: all 0.3s ease-in-out;

                                &:before {
                                    font-size: 24px;
                                }

                                &:after {
                                    content: '';
                                    width: 96px;
                                    height: 96px;
                                    border: 2px solid black;
                                }
                            }
                        }

                        .box-content {
                            background: #ffa800;

                            width: 100%;
                            height: auto;
                            text-align: center;
                            margin-top: 50px;
                            padding: 10px 0;

                            .title-video {
                                font-weight: 400;
                                margin: 0;
                                color: $colorsecondary ;
                            }
                        }
                    }
                }
            }

            .form-review {
                h5.title {
                    font-size: 18px;
                    font-weight: 500;
                    text-transform: uppercase;
                    padding-bottom: 34px;
                }

                p {
                    letter-spacing: -0.4px;

                    span {
                        color: #ff0101;
                    }
                }

                .list-star {
                    margin: 0;

                    li {
                        display: inline-block;

                        a {
                            color: $colortext;

                            i {
                                font-size: 12px;

                                &:before {
                                    margin-right: -2px;
                                }
                            }
                        }
                    }
                }
            }

            .boxitem .box-item-content .content-box {
                padding: 24px 30px 26px 30px;

                h6.subtitle {
                    padding-left: 3px;
                }

                h5.title {
                    margin-top: 10px;
                }
            }

            .img-detail {
                padding-top: 40px;
                padding-bottom: 40px;
                display: flex;

                img.img-dt {
                    padding-right: 20px;
                    width: 50%;
                }
            }
        }
    }



}

// end

//  Section Blog Details
.section-blog-details {
    background: $bg-section;
    padding-bottom: 130px;

    .main-blog-detail {
        display: flex;
    }

    .blog-content {
        width: 70%;
        padding-left: 30px;

        .img-blog {
            position: relative;

            .meta {
                position: absolute;
                z-index: 9;
                top: 22%;
                right: -86px;
                width: 230px;
                height: 40px;
                background: $colorhover;
                transform: rotate(-90deg);
                display: flex;
                align-items: center;
                justify-content: center;

                .time {
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: uppercase;
                    display: inline-block;
                    padding: 0 10px;
                }
            }
        }

        .img-blog.v1 {
            border: 10px solid #fff;
        }

        .content-blog {
            .category {
                background: rgb(243, 243, 244);
                padding: 10px;
                color: #11161e;
                font-size: 14px;
                text-transform: uppercase;

                &:hover {
                    color: $colorhover;
                }
            }

            h3.title-blog {
                font-size: 32px;
                font-weight: bold;
                text-transform: uppercase;
                padding-top: 15px;
                padding-bottom: 15px;
            }

            p {
                font-size: 16px;
                line-height: 1.875;
                color: $colortext;
            }

            .featured {
                border: 5px solid $colortext;
                padding: 25px 36px 28px 46px;
                margin-top: 40px;

                h5 {
                    font-size: 24px;
                    line-height: 1.667;
                    color: $colorprimary;
                    letter-spacing: -0.1px;
                }
            }

            .text-2 {
                display: inline-block;
                padding-top: 23px;

                p {
                    width: 50%;
                    float: left;
                    line-height: 1.875;
                }

                p.v2 {
                    padding-left: 14px;
                }
            }

            .review {
                h5.title {
                    padding-top: 1px;
                }

            }

            .form-review .list-star {
                display: none;
            }
        }
    }

    .slidebar {
        width: 32.5%;
    }

    .themesflat-blog {
        .arcire-blog-so {
            .left {
                width: 25%;
                float: left;
            }

            .right {
                width: 75%;
                float: right;

                .list-link {
                    float: left;
                    display: flex;
                    align-items: center;

                    a {
                        color: $colorprimary;
                        font-size: 20px;

                        &:hover {
                            color: $colorhover;
                        }
                    }

                    .text-link {
                        display: grid;
                        padding-left: 10px;

                        a {
                            font-size: 10px;

                            &:hover {
                                color: $colorhover;
                            }
                        }
                    }
                }

                .list-category {
                    float: right;
                    text-align: right;

                    p {
                        font-size: 10px;
                        color: $colorhover;
                    }

                    .list-btn {
                        a {
                            background: $colorprimary;
                            font-size: 10px;
                            padding: 5px;

                            &:hover {
                                color: $colorhover;
                            }
                        }
                    }
                }
            }
        }

        .content-blog.style-2 {
            display: inline-block;
        }
    }

    .themesflat-blog.style-3 {
        border: 8px solid rgb(230, 230, 230);
        padding: 40px 60px;

        .arcire-blog-s1 {
            .left {
                width: 80px;
                height: 80px;
                background: $colorhover;
                text-align: center;
                padding: 14px 0px;
                float: left;
                margin-right: 15px;
            }

            .right {
                .text-heading {
                    font-size: 18px;
                    font-weight: 500;
                    color: $colorprimary;
                }

                .name {
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: uppercase;
                }
            }
        }

        .content-blog.style-2 {
            padding: 30px 0;
        }
    }
}

// end

// section Blog Arcire
.section-blog-arcire {
    background: $bg-section;

    .main-blog-arcire {
        display: flex;

        .blog-content {
            width: 66%;
            margin-right: 128px;

            .themesflat-blog {
                .meta {
                    bottom: 132px;
                    right: -111px;
                    width: 284px;

                    .time {
                        padding-right: 87px;
                    }
                }

                .arcire-blog-so {
                    .left {
                        width: 25%;
                        float: left;
                    }

                    .right {
                        width: 75%;
                        float: right;
                        padding-left: 10px;

                        .list-link {
                            float: left;
                            display: flex;
                            align-items: center;

                            a {
                                color: $colorprimary;
                                font-size: 20px;

                                &:hover {
                                    color: $colorhover;
                                }
                            }

                            .text-link {
                                display: grid;
                                padding-left: 10px;

                                a {
                                    font-size: 10px;

                                    &:hover {
                                        color: $colorhover;
                                    }
                                }
                            }
                        }

                        .list-category {
                            float: right;
                            text-align: right;

                            p {
                                font-size: 10px;
                                color: $colorhover;
                            }

                            .list-btn {
                                a {
                                    background: $colorprimary;
                                    font-size: 10px;
                                    padding: 5px;

                                    &:hover {
                                        color: $colorhover;
                                    }
                                }
                            }
                        }
                    }
                }

                .content-blog {
                    p.text {
                        padding-right: 32px;
                    }
                }

                .content-blog.style-2 {
                    display: inline-block;
                }
            }

            .themesflat-blog.style-3 {
                border: 8px solid rgb(230, 230, 230);
                padding: 40px 30px 36px 60px;

                .arcire-blog-s1 {
                    .left {
                        width: 86px;
                        height: 84px;
                        background: #ffa800;
                        text-align: center;
                        padding: 19px 0px;
                        float: left;
                        margin-right: 13px;
                    }

                    .right {
                        padding-top: 11px;

                        .text-heading {
                            font-size: 18px;
                            font-weight: 500;
                            color: $colorprimary;
                        }

                        .name {
                            font-size: 14px;
                            font-weight: 400;
                            text-transform: uppercase;
                                    padding-top: 12px;
                            margin-left: 107px;
                        }
                    }
                }

                .content-blog.style-2 {
                    padding: 34px 0px 0px 0px;
                }
            }

            .themesflat-blog {
                .box-video.style-1 {
                    padding: 0;
                    position: relative;

                    .box {
                        padding: 110px 0 195px 0;
                        border: none;

                        .flat-video-fancybox {
                            top: 38%;
                        }
                    }
                }
            }
        }

        .blog-widget {
            width: 23%;

            .list-category {
                background: $colorsecondary;
                padding-bottom: 9px;

                .heading-top {
                    background: $colorhover;
                    padding: 12px 20px;

                    h5 {
                        font-size: 18px;
                        font-weight: 500;
                        text-transform: uppercase;

                        margin: 0;

                        i {
                            padding-right: 10px;
                        }
                    }
                }

                ul.list {
                    margin-bottom: 26px;

                    li {
                        padding-top: 25px;

                        a {
                            color: $colortext;

                            &:hover {
                                color: $colorprimary;
                            }
                        }
                    }
                }

                hr {
                    margin-bottom: 7px;
                }

                .btn-more {
                    color: $colorprimary;
                    font-size: 14px;
                    font-weight: bold;
                    padding: 0px 20px;

                    i {
                        font-size: 10px;
                        color: #ee324a;
                        padding-right: 3px;
                    }
                }
            }

            .list-recent-blog {
                .heading-top {
                    font-size: 18px;
                    font-weight: 500;
                    text-transform: uppercase;
                    padding-bottom: 43px;
                }

                .box-item {
                    img {
                        float: left;
                        padding-right: 15px;
                    }

                    .content {
                        h4.title {
                            font-size: 14px;
                            font-weight: 500;
                            text-transform: uppercase;
                            color: $colorprimary;
                            letter-spacing: 0;
                            margin: 0;

                            &:hover {
                                color: $colorhover;
                            }
                        }

                        a {
                            color: $colortext;
                            font-size: 13px;
                            font-family: 'Open Sans', sans-serif;
                            display: block;

                            &:hover {
                                color: $colorhover;
                            }

                            i {
                                padding-right: 5px;
                            }
                        }
                    }
                }
            }

            .list-recent-comments {
                .heading-top {
                    font-size: 18px;
                    font-weight: 500;
                    text-transform: uppercase;

                    padding-bottom: 43px;

                }

                .box-item {
                    img {
                        float: left;
                        padding-right: 15px;
                    }

                    .content {
                        h4.name {
                            font-size: 14px;
                            font-weight: 500;
                            text-transform: uppercase;
                            color: $colorprimary;
                            letter-spacing: 0;
                            margin: 0;

                            &:hover {
                                color: $colorhover;
                            }
                        }

                        a {
                            color: $colortext;
                            font-size: 13px;
                            font-family: 'Open Sans', sans-serif;
                            display: block;

                            &:hover {
                                color: $colorhover;
                            }

                            i {
                                padding-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }


}

// end

// section contact
.section-contact {
    background: $bg-section;

    .themesflat-map-1 {
        height: 570px;

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    .info-contact {
        .title {
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
            color: $colorprimary;
            display: inline;
            width: 30%;
        }

        span {
            font-size: 18px;
            font-weight: 400;
            color: $colortext;
            float: right;
            width: 70%;
        }

        .list-social {
            width: 70%;
            float: right;
            margin: 0;
            line-height: 0;

            li {
                display: inline-block;
                padding-right: 30px;

                a {
                    color: $colortext;
                    font-size: 24px;

                    &:hover {
                        color: #e74901;
                    }
                }
            }
        }
    }
}

// end

// Footer 
.footer {
    position: relative;

    .img-ft {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
    }

    .img-ft-2 {
        position: absolute;
        z-index: 10;
        bottom: 0;
        right: 0;
    }

    .section-main-footer {
        position: relative;
        background: url('../images/background/bg-footer.jpg') no-repeat;
        background-size: cover;
        background-position: top center;

        padding-top: 113px;
        padding-bottom: 102px;

        .list-footer {
            display: flex;

            .widget-about {
                width: 30%;
                padding-top: 7px;

                .text {
                    color: rgba(255, 255, 255, 0.6);
                    line-height: 2em;
                    padding-top: 20px;
                    padding-bottom: 35px;
                    letter-spacing: 0.1px;
                }

                .location {
                    .icon-location {
                        float: left;
                        padding-top: 15px;
                        padding-right: 28px;
                    }

                    .title {
                            font-size: 14px;
                        font-weight: 300;
                        text-transform: uppercase;
                        color: rgba(255, 255, 255, 0.6);
                    }

                    .info {
                            font-size: 18px;
                        font-weight: 300;
                        text-decoration: underline;
                        color: $colorhover;

                        padding-top: 5px;
                    }
                }
            }

            .widget-link {
                width: 20%;

                .widget-title {
                    font-size: 18px;
                    font-weight: 500;
                    text-transform: uppercase;
                    color: $colorsecondary;
                }

                .list-wrap {
                    margin: 0;
                    padding-top: 27px;

                    li {
                        padding: 10px 0;

                        a {
                            font-size: 16px;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 0.6);
                            position: relative;

                            &:before {
                                content: '';

                                position: absolute;
                                bottom: 0;
                                left: 0;

                                width: 1px;
                                height: 2px;
                                background: $colorhover;

                                opacity: 0;
                                visibility: hidden;

                                -webkit-transition: all 0.5s ease;
                                -moz-transition: all 0.5s ease;
                                -ms-transition: all 0.5s ease;
                                -o-transition: all 0.5s ease;
                                transition: all 0.5s ease;
                            }

                            &:hover {
                                color: $colorhover;
                                -webkit-transition: all 0.5s ease;
                                -moz-transition: all 0.5s ease;
                                -ms-transition: all 0.5s ease;
                                -o-transition: all 0.5s ease;
                                transition: all 0.5s ease;

                                &:before {
                                    opacity: 1;
                                    visibility: visible;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            .widget-link.v1 {
                padding-left: 10px;
            }

            .widget-link.v2 {
                padding-left: 17px;
            }

            .widget-link.v3 {
                padding-left: 20px;
            }

            .widget-recent-work {
                width: 30%;
                padding-left: 23px;
                margin-top: -2px;

                .widget-title {
                    font-size: 18px;
                    font-weight: 500;
                    text-transform: uppercase;
                    color: $colorsecondary;
                    padding-bottom: 18px;

                }

                .flat-imgbox {
                    padding-top: 6px;

                    .imgbox {
                        display: inline-block;
                        z-index: 99;
                        margin-right: 7px;

                        .imgbox-img {
                            position: relative;

                            img {
                                width: 100%;
                                display: block;
                            }

                            .overlay-img {
                                position: absolute;
                                z-index: 9;
                                top: 0;
                                left: 0;

                                -webkit-transition: all 0.3s ease;
                                -moz-transition: all 0.3s ease;
                                -ms-transition: all 0.3s ease;
                                -o-transition: all 0.3s ease;
                                transition: all 0.3s ease;

                                width: 100%;
                                height: 100%;
                                background: rgba(255, 168, 0, 0.5);
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                opacity: 0;

                                i {
                                    font-size: 20px;
                                    color: $colorprimary;
                                }
                            }
                        }

                        &:hover .overlay-img {
                            opacity: 1;

                            -webkit-transition: all 0.3s ease;
                            -moz-transition: all 0.3s ease;
                            -ms-transition: all 0.3s ease;
                            -o-transition: all 0.3s ease;
                            transition: all 0.3s ease;
                        }
                    }

                    .imgbox:nth-child(3) {
                        margin-right: 0;
                    }

                    .imgbox:nth-child(4) {
                        margin-top: 4px;
                    }

                    .imgbox:nth-child(6) {
                        margin-right: 0;
                    }
                }
            }
        }

        .overlay {
            position: absolute;
            z-index: 9;
            top: 0;
            left: 0;

            background: rgba(17, 22, 30, 0.9);
            width: 100%;
            height: 100%;
        }
    }

    .bottom-footer {
        background: rgb(17, 22, 30);
        padding: 27px 0;

        .copyright {
            padding-left: 8px;

            p {
                font-family: 'Oswald', sans-serif;
                font-size: 16px;
                color: $colorsecondary;

                span {
                    color: $colorhover;
                }
            }
        }

        .flat-text-right {
            float: right;
            margin-right: -10px;

            .list {
                margin: 0;

                li {
                    display: inline-block;
                    padding-left: 35px;

                    a {
                        color: rgba(255, 255, 255, 0.6);
                        font-size: 14px;
                        font-weight: 400;
                        text-transform: uppercase;

                        position: relative;

                        &:before {
                            content: '';

                            position: absolute;
                            bottom: 0;
                            left: 0;

                            width: 1px;
                            height: 2px;
                            background: $colorhover;

                            opacity: 0;
                            visibility: hidden;

                            -webkit-transition: all 0.5s ease;
                            -moz-transition: all 0.5s ease;
                            -ms-transition: all 0.5s ease;
                            -o-transition: all 0.5s ease;
                            transition: all 0.5s ease;
                        }

                        &:hover {
                            color: $colorhover;
                            -webkit-transition: all 0.5s ease;
                            -moz-transition: all 0.5s ease;
                            -ms-transition: all 0.5s ease;
                            -o-transition: all 0.5s ease;
                            transition: all 0.5s ease;

                            &:before {
                                opacity: 1;
                                visibility: visible;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

// End Footer


/* Scroll Top
-------------------------------------------------------------- */
#scroll-top {
    position: fixed;
    display: block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    z-index: 1;
    right: 14px;
    bottom: 23px;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    overflow: hidden;

}

#scroll-top.show {
    right: 24px;
    opacity: 1;
    visibility: visible;
    z-index: 9999;
}

#scroll-top:before,
#scroll-top:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

#scroll-top:before {
    z-index: -1;
    background-color: #3e3e3e;
}

#scroll-top:hover:before {
    background-color: $colorhover;
    color: $colorprimary;

}

// end