/* #region new portfoilo page  */

.section-portfolio .projects-sub-title {
    font-size: 1.5rem;
    text-transform: uppercase;
}

html[lang="en"] .section-portfolio .projects-title {
    font-family: 'HeadingNow', sans-serif;
    font-size: 8rem;
    font-weight: 400;

}

.section-portfolio .filter-div {
    padding: 1rem 2rem;
    display: flex;
    align-items: flex-end;

}

.section-portfolio .filter-div button {
    background: none;
    border: none;
    font-size: 2rem;
    padding: 1rem 0.5em;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    color: #111;
    cursor: pointer;
    transition: all 0.3s ease;
}

.section-portfolio .filter-div button:hover {
    text-decoration: underline;
    color: var(--main-color);
}

.section-portfolio .filter-div button.active {
    text-decoration: underline;
    color: var(--main-color);

}

.section-portfolio .filter-div button .btn-span-number {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1rem;
    font-weight: 400;

}


html[lang="en"] .section-portfolio .card-projects-diplay .content-box h3 {
    font-family: 'HeadingNow', sans-serif;
    font-size: 6rem;
    font-weight: 500;
    letter-spacing: 1.2px;
    line-height: 1.2;
}


/* #endregion */



/* #region Single Project New Style */
.section-project-details .project-side-details{
    order: 3 !important;
}
.section-project-details .project-side-details .side-details-info-divs div .inner-contetext {
    border-top: 1px solid #111;
    padding: 1rem 1rem;
    margin-top: 1rem;

}



/* #endregion Single Project New Style */