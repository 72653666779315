.list-footer .widget-link.about-col-eng-footer {
    padding-left: 70px !important;
}
.list-footer .widget-link.about-col-ar-footer {
    padding-right: 70px !important;
}
@media (max-width: 775px) {
    .list-footer .widget-link.about-col-eng-footer {
        padding-left: 20px !important;
    }
    .list-footer .widget-link.about-col-ar-footer {
        padding-right: 20px !important;
    }
    .facts-content .heading {
        margin-bottom: 20px;
    }
    .section-facts .facts-content .form-contact input {
        min-width: 250px !important;
    }
}