@media only screen and (max-width: 1366px) {

    .header .main-header .main-header-contact {
        padding-right: 16px;
    }

    .header img.img-top-page {
        display: none;
    }

    .header .main-header .main-header-logo {
        padding-left: 16px;
        padding-top: 18px;
    }

    /* html[lang="ar"] .header .main-header .main-header-logo {
        padding-right: 16px;
        padding-top: 18px;
    } */

    .header .main-header .main-nav {
        position: absolute;
        left: 19.9%;
        top: 51px;
    }

    .header .main-header .main-nav .menu li.menu-item-has-children {
        padding-right: 30px;
    }

    .header .main-header .main-header-contact .text-phone {
        padding-right: 15px;
    }

    .header.header.style-2 .main-header .main-nav .menu li.menu-item-has-children {
        padding-right: 25px;
    }

    .header .main-header .main-header-contact .text-phone.style-2 {
        color: #11161e;
    }

    .themesflat-contact-form.style-2 .wpcf7-form-control-wrap.your-name {
        margin-right: 23px;
    }

    .section-blog-arcire .main-blog-arcire .blog-content .themesflat-blog .meta {
        bottom: 130px;
        right: -101px;
        width: 275px;
    }

    .section-blog-arcire .main-blog-arcire .blog-content .themesflat-blog .meta .time {
        padding-right: 40px;
    }
}

@media only screen and (max-width: 1200px) {
    .section-blog-arcire .main-blog-arcire .blog-content .themesflat-blog .meta {
        bottom: 98px;
        right: -76px;
        width: 215px;
    }

    .section-blog-details .blog-content .img-blog .meta {
        top: 30%;
    }

    .section-blog.page-blog .themesflat-blog .meta {
        bottom: 79px;
        right: -57px;
        width: 64%;

    }

    .section-blog.page-blog .themesflat-blog .meta .time {
        padding-right: 6px;
    }
}

@media only screen and (max-width: 991px) {


    .cm-header-logo img {
        width: 100px !important;
    }

    .cm-header-nav ul {
        position: fixed;
        top: 20vh;
        right: 0;
        background-color: #fefefe;
        width: 100%;
        height: 80vh;
        flex-direction: column !important;
        transform: translateX(100vw) !important;
        transition: all 0.8s ease !important;

    }

    .cm-header-nav ul.show {

        transform: translateX(0) !important;
    }




    .cm-header-nav ul li {
        padding: 1rem !important;
    }

    .cm-header-nav ul li a {
        color: #262626;
        display: block;
        width: 100%;
        padding: 0 1rem;
        font-size: 1.5rem;
    }




    /*#region carrers-section*/
    .secyion-carrer .cm-table td, .secyion-carrer .cm-table th {
        padding: 2rem .5rem !important;
    }

    .secyion-carrer .cm-table td a {

        padding: 1rem !important;
        font-size: 1.3rem !important;
    }

    .secyion-carrer .cm-table .postions-title {

        font-size: 1.5rem !important;
    }

    .section-contact .form-contact h2 {
        font-size: 3rem !important;
    }



    /*#endregion carrers-section*/























    .main-header-contact, .contact-whatsapp {
        display: none;
    }

    .section-custom-slider .carousel .custom-caption {
        text-align: center !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .carousel .custom-caption div h2 {
        font-size: 50px;
    }

    .carousel .custom-caption div h3 {
        font-size: 20px !important;
    }



    /* work */
    .section-work.style-2.style-3.s1 {
        padding-top: 100px;
    }

    .section-work .list-box-work {
        flex-wrap: wrap;
    }

    /* .section-work .list-box-work .box-work {
        width: 48%;
        margin-right: 29px;
        margin-bottom: 30px;
    } */

    /* .section-work .list-box-work .box-work:nth-child(2) {
        margin-right: 0;
    }

    .section-work .list-box-work .box-work:nth-child(4) {
        margin-right: 0;
    } */

    /* .section-work.style-2 .list-box-work .box-work {
        width: 47%;
    }

    .section-work.style-2 .list-box-work .box-work:nth-child(3) {
        margin-top: 30px;
    } */

    /* .section-work.style-2 .list-box-work .box-work:nth-child(4) {
        margin-top: 30px;
    } */

    /* .section-work.style-2 .list-box-work .box-work:nth-child(6) {
        margin-right: 0;
    } */

    .section-work.style-2 .list-box-work .form-portfolio {
        width: 100%;
        margin-top: 30px;
    }

    .section-work.style-2.style-3 {
        padding-bottom: 80px;
    }

    /* end */
    /* Meta blog */
    .section-blog-details .blog-content .img-blog .meta {

        top: 25%;
        right: -76px;
        width: 230px;
        height: 45px;
    }

    .section-blog-details .img-blog.v1 img {
        width: 100%;
    }

    .section-blog-arcire .main-blog-arcire .box-video .box .flat-video-fancybox {
        padding: 0 180px;
    }

    .section-blog-arcire .main-blog-arcire .blog-content {
        margin-right: 20px;
    }

    .section-blog-arcire .main-blog-arcire {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .section-blog-arcire .main-blog-arcire .blog-content {
        margin-top: 100px;
        width: 100%;
    }

    .section-blog-arcire .main-blog-arcire .blog-widget {
        width: 100%;
    }

    .section-blog-arcire .main-blog-arcire .blog-content .themesflat-blog .meta {
        bottom: 127px;
        right: -100px;
        width: 280px;
    }

    .section-blog.page-blog .themesflat-blog .meta {
        bottom: 10px;
        right: 7px;
        width: 93%;
    }

    /* end */
    .section-contact .themesflat-contact-form.style-2 .wpcf7-form-control-wrap.your-name {
        margin-right: 15px;
    }

    .mobile-button {
        display: block !important;
    }

    .tf-section {
        padding: 120px 0;
    }

    /* end */
    /* Dot Slide */
    .tp-bullets.custom.horizontal.nav-pos-hor-center.nav-pos-ver-bottom.nav-dir-horizontal {
        top: 89.4% !important;
        left: 18.9% !important;
    }

    /* end */
    #main-nav,
    .menu-has-search #site-header #header-search,
    .menu-has-cart #site-header .nav-top-cart-wrapper,
    #top-bar .top-bar-content,
    .switcher-container,
    .row-information {
        display: none;
    }

    #top-bar .top-bar-socials {
        width: 100%;
        display: block;
        text-align: center;
    }

    #main-content {
        padding: 60px 0px;
    }

    #featured-title .featured-title-heading-wrap,
    #featured-title #breadcrumbs {
        width: 50%;
    }

    .sidebar-right #site-content,
    .sidebar-right #sidebar,
    .sidebar-left #sidebar,
    .sidebar-left #site-content,
    .sidebar-right.width-71 #site-content,
    .sidebar-right.width-73 #site-content {
        float: none;
        width: 100%;
    }

    .site-header-absolute #site-header-wrap {
        position: relative;
    }

    .header-style-3 #site-header {
        background-color: #3e3e3e;
    }

    .topbar-style-3 #top-bar {
        background-color: #262626;
    }

    .themesflat-row .span_1_of_3 {
        width: 50%;
    }

    .themesflat-row.gutter-30>[class*="col"]:nth-child(3) {
        clear: both;
    }

    #footer {
        padding: 60px 0px;
    }

    #bottom .bottom-bar-menu,
    #bottom .bottom-bar-content {
        width: 50%;
        text-align: center;
        vertical-align: top;
    }

    .themesflat-row.separator.drank>[class*="span_"],
    .themesflat-row.separator.light>[class*="col"] {
        border-left: 0px;
    }

    .widget.widget_instagram .instagram-wrap.g10 .instagram_badge_image img {
        width: 100%;
        display: block;
    }

    .comment-list.style-2 article {
        padding: 15px;
    }

    .themesflat-row .col.span_1_of_4 {
        width: 100%;
    }

    .divider.h35 {
        height: 35px;
        display: none;
        height: 0;
    }

    /* Header */

    /* logo */
    html[lang='en'] .header .main-header .main-header-logo {
        padding-left: 0;
        padding-top: 18px;
    }

    html[lang='ar'] .header .main-header .main-header-logo {
        padding-right: 0;
        padding-top: 18px;
    }

    /* button menu */
    .header .main-header .mobile-button {
        display: none;
        position: absolute;
        z-index: 99999;
        width: 26px;
        height: 26px;
        float: left;
        top: 61%;
        right: 16px;
        background-color: transparent;
        cursor: pointer;
    }

    /* html[lang="ar"] .header .main-header .mobile-button {
        display: none;
        position: absolute;
        z-index: 99999;
        width: 26px;
        height: 26px;
        float: right;
        top: 61%;
        left: 16px;
        background-color: transparent;
        cursor: pointer;
    } */

    .header .main-header .main-header-contact .call {
        display: none;
    }

    .header .main-header .main-header-contact {
        padding-right: 250px;
    }

    .header .main-header.style-2 .main-header-contact {
        padding-right: 100px;
    }

    .header .main-header .main-header-contact .text-phone.style-2 {
        color: #11161e;
    }

    /* img-top */
    .header img.img-top-page {
        display: none;
    }

    .header.style-2 {
        padding-left: 0;
    }

    /* end */
    /* slider */
    .section-slider .menu-slide {
        right: 16px;
    }

    .section-slider img.before-slide {
        left: 16px;
    }

    .section-slider.style-3 .tp-mask-wrap {
        margin-left: -25px;
    }

    .section-slider.style-3 .tp-bullets.custom.horizontal.nav-pos-hor-center.nav-pos-ver-bottom.nav-dir-horizontal {
        left: 19.9% !important;
    }

    /* end */

    .section-about .image-about {
        text-align: center !important;
    }

    .section-about .image-about img {
        width: 100%;
    }

    .section-about.style-2 .intro-content {
        padding-left: 0;
    }

    .image-about.style-2 img {
        width: 100%;
    }

    .responsive-item {
        margin-top: 30px;
    }

    .responsive-item-1 {
        margin-top: 40px;
    }

    .responsive-item-footer {
        margin-top: 50px;
    }

    .responsive-padding-top-20 {
        padding-top: 20px;
        padding-left: 0;
        padding-right: 0;
    }

    /* counter */
    .section-counter .counter-item {
        padding: 0;
    }

    .section-counter .counter-item .number-wrap .number {
        font-size: 50px;
    }

    .section-counter .list-counter .themesflat-content-box {
        padding: 0 23px;
    }

    /* end */
    .section-video .box-video {
        padding: 0;
        margin-top: -250px;
    }

    .section-pricing .themesflat-pricing .pricing-item .thumb {
        padding: 70px 9px;
    }

    .padding-responsive {
        padding: 0 10px;
    }

    .section-blog .themesflat-blog .meta {
        bottom: 10px;
        right: 12px;
    }

    .section-blog .themesflat-blog .meta {
        width: 90%;
        height: 40px;
        transform: none;
    }

    .themesflat-blog .meta .time {
        padding-right: 15px;
    }

    .section-blog-arcire .blog-content .img-blog img {
        width: 100%;
    }

    .section-blog-details .main-blog-detail {
        flex-wrap: wrap;
    }

    .section-blog-details .blog-content {
        width: 100%;
        padding-left: 0;
        margin-top: 100px;
    }

    .section-blog-details .slidebar {
        width: 100%;
    }

    .copyright {
        text-align: center;
    }

    .footer .bottom-footer .flat-text-right {
        padding-top: 10px;
        float: none;
        text-align: center;
    }

    .footer .img-ft-2 {
        display: none;
    }

    .footer .bottom-footer .flat-text-right .list li {
        padding: 0 15px;
    }

    /* divifer */
    .divider {
        width: 230px;
    }

    .divider::before {
        width: 115px;
    }

    .section-slider.style-2 .tp-mask-wrap {
        margin-left: -25px;
    }

    .section-slider.style-2 .menu-slide .list-menu-slide li a {
        color: #11161e;
    }

    .header.style-2 hr.divider-header {
        margin-top: 5px;
    }

    .header.style-2 .main-header .mobile-button {
        top: 42%;
    }

    .section-video .box-video .box .flat-video-fancybox {
        padding: 0 100px;
    }

    /* main Project Detail */
    .section-project-details .main-project-details {
        display: flex;
        flex-wrap: wrap;
        /* flex-direction: column-reverse; */
    }

    .section-project-details .main-project-details .project-content {
        margin-top: 100px;
        width: 100%;
    }

    .section-project-details .main-project-details .slidebar {
        width: 100%;
    }

    .review .review-content .content .list-action li {
        padding-right: 10px;
    }

    .review .review-content .content {
        padding-left: 30px;
        padding-right: 30px;
    }

    /* end */
    /* box Author Project Details */

    /* couter */
    .section-counter .list-counter {
        display: flex;
        flex-wrap: wrap;
    }

    .section-counter .list-counter .themesflat-content-box .counter-item .heading {
        margin-top: 24px;
    }

    /* end */
    /* Partner */
    .themesflat-carousel-box.clearfix {
        padding: 0 60px;
    }

    .section-partner.style-2 {
        padding-bottom: 80px;
    }

    /* end */
    .slidebar .box-author {
        padding: 50px 20px;
    }

    .slidebar .box-author li.fb-author {
        left: 40%;
    }

    .slidebar .box-author .list-social li {
        padding: 0 20px
    }

    .slidebar .box-search .search-form input.header-search-field {
        font-size: 12px;
        padding: 20px 10px;
    }

    .slidebar .box-search .search-form button.header-search-submit {
        right: 0px;
    }

    .slidebar .box-search {
        padding: 50px 20px;
    }

    .slidebar .box-category {
        padding: 50px 20px;
    }

    /* end */

    /* box portfolio */
    .section-portfolio .list-portfolio .themesflat-portfolio {
        margin-right: 15px;
    }

    .section-portfolio .list-portfolio .themesflat-portfolio .boxitem .box-item-content {
        padding: 0 10px;
    }

    .section-portfolio .list-portfolio .themesflat-portfolio .boxitem .box-item-content .content-box h5.title {
        font-size: 15px;
    }

    .section-portfolio .list-portfolio .themesflat-portfolio .boxitem .box-item-content .content-box {
        padding: 15px;
    }

    .section-portfolio.style-2 {
        padding-left: 0;
    }

    /* end */
    /* Box Pricing */
    .section-pricing .list-pricing .themesflat-pricing .pricing-item .thumb {
        padding: 70px 20px 80px 20px;
    }

    /* end */
    /* blog */
    .section-blog {
        margin-left: 0;
    }

    .section-blog-details .blog-content .content-blog .text-2 p {
        width: 100%;

    }

    .section-blog-details .blog-content .content-blog .text-2 p.v2 {
        padding-top: 20px;
        padding-left: 0;
    }

    /* end */
    /* footer */
    .footer .section-main-footer .list-footer {
        flex-wrap: wrap;
    }

    /* .footer .section-main-footer .list-footer .widget-about {
        width: 40%;
    } */

    /* .footer .section-main-footer .list-footer .widget-link {
        width: 20%;
    } */

    .footer .section-main-footer .list-footer .widget-recent-work {
        width: 100%;
        padding-left: 0;
        margin-top: 30px;
    }

    /* end */
    /* team */
    .section-team .list-team {
        flex-wrap: wrap;
    }

    .section-team .list-team .box-item-team {
        width: 47.9%;
        text-align: center;
    }

    .section-team .list-team .box-item-team .imgbox {
        width: 100%;
    }

    .section-team .list-team .box-item-team:nth-child(2) {
        margin-right: 0;
    }

    .section-team .list-team .box-item-team:nth-child(3) {
        margin-top: 40px;
    }

    .section-team .list-team .box-item-team:nth-child(4) {
        margin-right: 0;
        margin-top: 40px;
    }

    .section-team .list-team .box-item-team .info {
        left: 62px;
    }

    /* end */


    /* #region New Portfoilo */

    .section-portfolio .title-div {

        text-align: center;

    }

    .section-portfolio .projects-sub-title {
        font-size: 1.3rem !important;
    }

    .section-portfolio .projects-title {
        font-size: 5rem !important;


    }

    .section-portfolio .filter-div button {
        font-size: 1.5rem !important;
    }




    /* #endregion New Portfoilo */

    /* #region Video */
    .box-video .box .flat-video-fancybox .box-content {
        margin-top: 1rem !important;
    }

    .box-video .box .flat-video-fancybox .box-content .title-video {
        font-size: 2rem;
    }

    /* #endregion Video */



}

@media only screen and (max-width: 767px) {

    .section-custom-slider img.mobile {
        display: block !important;
    }

    .section-custom-slider img.desktop {
        display: none !important;
    }


    .tf-section {
        padding: 100px 0;
    }

    .no-padding-mobi {
        padding: 0 !important;
    }

    .no-padding-top-mobi {
        padding-top: 0 !important;
    }

    .main-header-contact {
        display: none;
    }

    .col-logo {
        width: 100%;
    }

    .header .main-header .main-header-logo {
        padding: 0;
        text-align: center;
        padding-bottom: 10px;
        padding-left: 16px;
    }

    .header .main-header .main-header-contact {
        padding-right: 16px;
    }

    .header .main-header .main-header-contact .text-phone {
        color: #fff;
        float: left;
        padding-right: 15px;
    }

    .header .main-header .main-header-contact .text-phone p {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
    }

    .header .main-header .main-header-contact .text-phone span {
        font-size: 20px;
    }

    .header .main-header .mobile-button {
        top: 45%;
        right: 16px;
    }

    /* html[lang='ar']  .header .main-header .mobile-button {
        top: 45%;
        left: 16px;
    }
    html[lang='ar'] .header .main-header .main-header-logo{
        padding-right: 0;
    } */

    /* about  */
    .section-about .intro-content {
        padding-top: 80px;
    }

    /* end */
    /* box work */

    /* .section-work .list-box-work .box-work {
        width: 100%;
        margin-right: 0px;
    } */

    .section-work.style-2 .description-right {
        padding-left: 0;
    }

    /* .section-work.style-2 .list-box-work .box-work {
        width: 45%;
    } */

    .section-work.style-2 .list-box-work .form-portfolio {
        padding: 39px 30px 0px 30px;
    }

    .section-work.style-2 .list-box-work .form-portfolio .heading-form img {
        padding-right: 20px;
    }

    .section-work.style-2.style-3 {
        padding-bottom: 80px;
    }

    /* end */
    /* box portfolio */
    .section-portfolio .list-portfolio {
        flex-wrap: wrap;
    }

    .section-portfolio .list-portfolio .themesflat-portfolio {
        margin-right: 0;
        width: 100%;
        margin-bottom: 30px;
    }

    .section-portfolio .list-portfolio .themesflat-portfolio .boxitem .box-item-content {
        padding: 0 20px;
    }

    .section-portfolio .list-portfolio .themesflat-portfolio .boxitem .box-item-content .content-box {
        padding: 23px 30px;
    }

    .section-portfolio .list-portfolio .themesflat-portfolio .boxitem .box-item-content .content-box h5.title {
        font-size: 18px;
    }

    .section-portfolio.style-2 .list-portfolio .themesflat-portfolio {
        margin-right: 0;
    }

    .section-portfolio .description-right {
        padding-left: 0;
    }

    .section-portfolio.style-2 {
        padding-top: 100px !important;
    }

    .section-about.service-detail .intro-content img.headphone {
        top: 40px;
        left: 17px;
    }

    /* end */

    #site-header .wrap-inner {
        padding: 0px;
    }

    #bottom .bottom-bar-content,
    #bottom .bottom-bar-menu {
        width: 100%;
        display: block;
    }

    #bottom .bottom-bar-menu {
        margin-top: 10px;
    }

    .hentry .box-excerpt .thumb {
        margin: 0px 0px 27px 0px;
        float: none;
    }

    .comment-respond {
        padding-top: 35px;
    }

    .custom.tparrows,
    .custom .tp-bullet {
        display: none;
    }

    .separator-solid .flat-content-wrap {
        border-bottom: 0px;
    }

    .themesflat-row .span_1_of_3,
    .themesflat-row .span_1_of_6,
    .themesflat-row.gutter-mobile .span_1_of_3,
    .themesflat-row .span_1_of_4 {
        width: 100%;
    }

    .padding-left-13 {
        padding: 0px !important;
    }

    .widget.widget_instagram .instagram_badge_image img {
        display: block;
        width: 100%;
    }

    .header #main-nav-mobi ul li {
        text-align: left;
    }

    .section-slider {
        margin-top: -220px;
    }

    .header .main-header .main-header-contact {
        padding-top: 20px;
    }

    .header .main-header {
        padding: 22px 0 8px 0;
    }

    .section-slider img.after-slide {
        top: 24%;
    }

    .section-slider .tp-mask-wrap {
        padding-top: 170px;
        margin-left: -69px;
    }

    /* slider */
    .section-slider .tp-caption.tp-resizeme.text-white.font-heading.font-weight-700.padding-top-100.text-slide {
        margin-top: 340px !important;
        padding-right: 60px !important;
    }

    .padding-btn-sl {
        padding-top: 370px !important;
    }

    .section-slider .menu-slide {
        bottom: 80px;
    }

    .section-slider .menu-slide {
        right: 15px;
    }

    .section-slider .menu-slide {
        right: 15px;
        left: 15px;
    }

    .section-slider .menu-slide .list-menu-slide li {
        padding-left: 22px;
    }

    .section-slider.style-3 .tp-mask-wrap {
        margin-left: -69px;
    }

    /* end */
    .section-counter .counter-item .number-wrap .number {
        font-size: 42px;
    }

    .section-video .box-video .box .flat-video-fancybox .overlay-inner i {
        left: 36%;
    }

    h3.title-video {
        font-size: 24px;
    }

    .section-facts .facts-content h2.heading {
        font-size: 32px;
    }

    /* blog */
    .section-blog .list-blog {
        display: flex;
        flex-wrap: wrap;
    }

    .section-blog .list-blog .themesflat-blog {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
    }

    .section-blog .list-blog .themesflat-blog:nth-child(3) {
        margin-bottom: 0;
    }

    .themesflat-blog .meta {
        transform: rotate(-90deg) !important;
        bottom: 134px !important;
        right: -107px !important;
        width: 66% !important;
        height: 40px !important;
    }

    .themesflat-blog .img-blog img {
        width: 100%;
    }

    /* Blog arcire */
    .section-blog-arcire .main-blog-arcire {
        flex-wrap: wrap;
    }

    .section-blog-arcire .main-blog-arcire .blog-content {
        width: 100%;
        margin-right: 0;
    }

    .section-blog-arcire .main-blog-arcire .blog-widget {
        width: 100%;
    }

    .section-blog-arcire .main-blog-arcire .blog-content .themesflat-blog .meta {
        transform: none !important;
        right: 48px !important;
        bottom: 9px !important;
        width: 330px !important;
        height: 40px !important;
    }

    .section-blog-arcire .main-blog-arcire .blog-content .themesflat-blog .meta .time {
        padding-right: 45px;
    }

    .section-blog-arcire .main-blog-arcire .blog-content .themesflat-blog.style-3 {
        padding: 40px 0px 36px 20px;
    }

    .section-blog-arcire .main-blog-arcire .blog-content .themesflat-blog.style-3 .arcire-blog-s1 .right {
        padding-top: 0;
    }

    .section-blog-arcire .main-blog-arcire .box-video .box .flat-video-fancybox {
        padding: 0 130px;
    }

    .section-blog-arcire .blog-content .themesflat-blog.style-3 {
        padding: 40px 15px;
    }

    .section-blog-details .blog-content .img-blog .meta {
        top: 41%;
    }

    .section-blog.page-blog .themesflat-blog .meta .time {
        padding-right: 80px;
    }




    /* end */

    .divider {
        width: 320px;
    }

    .divider::before {
        width: 160px;
    }

    .padding-responsive {
        padding: 0;
    }

    /* no padding col bootstrap */

    .section-slider.style-2 .tp-mask-wrap {
        margin-left: -69px;
    }

    /* team */
    .section-team .box-item-team {
        text-align: center;
    }

    .section-team .box-item-team .info {
        bottom: 98px;
        left: 49px;
    }

    .section-team .list-team {
        flex-wrap: wrap;
    }

    .section-team .list-team .box-item-team {
        width: 100%;
    }

    .section-team .list-team .box-item-team {
        margin-right: 0;
        margin-top: 30px;
    }

    .section-team .list-team .box-item-team .imgbox {
        width: 100%;
    }

    .section-team .list-team .box-item-team .info {
        left: 106px;
    }

    /* end */

    .header .main-header.style-2 .main-header-contact {
        padding-right: 10px;
        float: right;
    }

    /* testimonial */
    .section-testimonials.style-3 .testimonials-item .thumb {
        padding: 70px 30px 70px 30px;
    }

    .section-testimonials.style-2 .description-right {
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 40px;
    }

    .has-bullets .owl-theme .owl-dots {
        display: none;
    }

    .section-testimonials.s2 {
        padding-top: 100px !important;
    }

    /* end */
    .page-title-about {
        padding: 170px 16px;
    }

    .section-work.style-2 .form-portfolio {
        padding: 25px 50px;
    }

    .section-portfolio .list-portfolio .boxitem .box-item-content .content-box h5.title {
        font-size: 16px;
    }

    .section-portfolio .list-portfolio .boxitem .box-item-content .content-box:after {
        right: 0px;
    }

    .section-video .box-video .box .flat-video-fancybox {
        padding: 0 20px;
    }

    .page-title {
        padding: 170px 0;
    }

    /* main Project Detail */
    .review .review-content .content .list-action li {
        padding-right: 10px;
    }

    .themesflat-contact-form.style-2 .wpcf7-form-control-wrap.your-name {
        margin-right: 12px;
    }

    .review .review-content .content {
        padding-left: 20px;
        padding-right: 30px;
    }

    .review .review-content .content .list-star {
        right: 20px;
    }

    .review .review-content.rep {
        padding-left: 50px;
    }

    /* end */

    /* box Author Project Details */
    .slidebar .box-author li.fb-author {
        left: 30%;
    }

    /* end */
    .section-contact .themesflat-contact-form.style-2 .wpcf7-form-control-wrap.your-name {
        margin-right: 12px;
    }


    /* couter */
    .section-counter .list-counter .themesflat-content-box {
        width: 50%;
        margin-bottom: 30px;
    }

    .section-counter.style-2 {
        padding-bottom: 40px !important;
    }

    .section-counter .list-counter .themesflat-content-box .themesflat-counter.v3 {
        margin-top: 60px;
    }

    .section-counter .list-counter .themesflat-content-box .themesflat-counter.v4 {
        margin-top: 60px;
    }

    /* end */
    /* pricing */
    .section-pricing .list-pricing {
        display: flex;
        flex-wrap: wrap;
    }

    .section-pricing .list-pricing .themesflat-pricing {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
    }

    .section-pricing .list-pricing .themesflat-pricing:nth-child(3) {
        margin-bottom: 0;
    }

    /* end */


    /* project Detail */
    .section-project-details .main-project-details {
        flex-wrap: wrap;
    }

    .section-project-details .main-project-details .project-content {
        width: 100%;
        margin-right: 0;
    }

    .slidebar {
        width: 100%;
        padding-top: 80px;
    }

    .section-project-details .main-project-details .project-content .box-video .box .flat-video-fancybox {
        left: -28%;
    }

    /* end */
    /* footer */
    /* .footer .section-main-footer .list-footer .widget-about {
        width: 60%;
    } */

    /* .footer .section-main-footer .list-footer .widget-link {
        width: 40%;
        padding-left: 0;
        margin-top: 40px;
    } */
    .footer .section-main-footer .list-footer .widget-link {

        padding-left: 0;
        margin-top: 40px;
    }

    .footer .section-main-footer .list-footer .widget-link.v1 {
        padding-left: 20px;
        margin-top: 18px;
    }

    .footer .section-main-footer .list-footer .widget-link.v2 {
        padding-left: 0;
    }

    .footer .section-main-footer .list-footer .widget-recent-work {
        width: 100%;
        padding-left: 0;
        margin-top: 40px;
    }

    /* end */


}

@media only screen and (max-width: 479px) {

    .carousel .custom-caption div h2 {
        font-size: 30px;
    }

    .carousel .custom-caption div h3 {
        font-size: 16px !important;
    }

    .carousel .custom-caption div h5 {
        font-size: 12px !important;
        width: 80%;
        margin: auto;

    }

    .tf-section {
        padding: 100px 0px;
    }

    .no-padding-mobi {
        padding: 0 !important;
    }

    .no-padding-top-mobi {
        padding-top: 0 !important;
    }

    .main-header-contact {
        display: none;
    }

    .header #main-nav-mobi ul li {
        text-align: left;
    }

    .section-slider {
        margin-top: -230px;
    }

    /* box work */
    /* .section-work .list-box-work .box-work {
        width: 100%;
        margin-right: 0px;
    }

    .section-work .list-box-work .box-work:nth-child(4) {
        margin-bottom: 0;
    } */

    .section-work.style-2 .description-right {
        padding-left: 0;
    }

    /* .section-work.style-2 .list-box-work .box-work {
        width: 45%;
    } */

    .section-work.style-2 .list-box-work .form-portfolio {
        padding: 39px 30px 0px 30px;
    }

    .section-work.style-2 .list-box-work .form-portfolio .heading-form img {
        padding-right: 20px;
    }

    /* end */

    /* team */
    .section-team .box-item-team {
        text-align: center;
    }

    .section-team .box-item-team .info {
        bottom: 98px;
        left: 49px;
    }

    .section-team .list-team {
        flex-wrap: wrap;
    }

    .section-team .list-team .box-item-team {
        width: 100%;
    }

    .section-team .list-team .box-item-team {
        margin-right: 0;
        margin-top: 30px;
    }

    .section-team .list-team .box-item-team .imgbox {
        width: 100%;
    }

    .section-team .list-team .box-item-team .info {
        left: 62px;
    }

    /* end */
    .header .main-header .main-header-contact {
        padding-top: 20px;
    }

    .header .main-header {
        padding: 22px 0 8px 0;
    }

    html[lang="en"] .header .main-header .mobile-button {
        top: 50%;
        right: 16px;
    }

    html[lang="ar"].header .main-header .mobile-button {
        top: 50%;
        right: 16px;
    }

    /* Slide */
    .section-slider img.after-slide {
        top: 24%;
    }

    .section-slider .tp-mask-wrap {
        padding-top: 170px;
        margin-left: -15px;
    }

    .section-slider .tp-caption.tp-resizeme.text-white.font-heading.font-weight-700.padding-top-100.text-slide {
        margin-top: 340px !important;
        padding-right: 60px !important;
    }

    .padding-btn-sl {
        padding-top: 370px !important;
    }

    .section-slider .menu-slide {
        bottom: 80px;
    }

    .section-slider .menu-slide {
        right: 15px;
        left: 15px;
    }

    .section-slider .menu-slide .list-menu-slide li {
        padding-left: 22px;
    }

    .section-slider.style-3 .tp-mask-wrap {
        margin-left: -16px;
    }

    /* end */
    /* counter */
    .section-counter .counter-item .number-wrap .number {
        font-size: 42px !important;
    }

    .section-video .box-video .box .flat-video-fancybox .overlay-inner i {
        left: 36%;
    }

    h3.title-video {
        font-size: 24px;
    }

    .section-counter.style-2 {
        padding-bottom: 40px !important;
    }

    .section-pricing .description-right {
        padding-left: 0;
    }

    .section-counter .list-counter .themesflat-content-box .themesflat-counter.v3 {
        margin-top: 0px;
    }

    .section-counter .list-counter .themesflat-content-box .themesflat-counter.v4 {
        margin-top: 0px;
    }

    /* end */

    /* box portfolio */
    .section-portfolio .list-portfolio {
        flex-wrap: wrap;
    }

    .section-portfolio .list-portfolio .themesflat-portfolio {
        margin-right: 0;
        width: 100%;
        margin-bottom: 30px;
    }

    .section-portfolio .list-portfolio .themesflat-portfolio:nth-child(5) {
        margin-bottom: 0;
    }

    .section-portfolio .list-portfolio .themesflat-portfolio .boxitem .box-item-content {
        padding: 0 20px;
    }

    .section-portfolio .list-portfolio .themesflat-portfolio .boxitem .box-item-content .content-box {
        padding: 23px 30px;
    }

    .section-portfolio .list-portfolio .themesflat-portfolio .boxitem .box-item-content .content-box h5.title {
        font-size: 18px;
    }

    .section-portfolio.style-2 .list-portfolio .themesflat-portfolio {
        margin-right: 0;
    }

    .section-portfolio .description-right {
        padding-left: 0;
    }

    /* end */
    .section-facts .facts-content h2.heading {
        font-size: 32px;
    }

    /* blog */
    .section-blog-arcire .main-blog-arcire {
        flex-wrap: wrap;
    }

    .section-blog-arcire .main-blog-arcire .blog-content {
        width: 100%;
        margin-right: 0;
    }

    .section-blog-arcire .main-blog-arcire .blog-widget {
        width: 100%;
    }

    .section-blog-arcire .main-blog-arcire .blog-content .themesflat-blog .meta {
        transform: none !important;
        bottom: 10px !important;
        right: 33px !important;
        width: 260px !important;
        height: 40px !important;
    }

    .section-blog-arcire .main-blog-arcire .blog-content .themesflat-blog .meta .time {
        padding-right: 45px;
    }

    .section-blog-arcire .main-blog-arcire .blog-content .themesflat-blog.style-3 {
        padding: 40px 0px 36px 20px;
    }

    .section-blog-arcire .main-blog-arcire .blog-content .themesflat-blog.style-3 .arcire-blog-s1 .right {
        padding-top: 0;
    }

    .section-blog-arcire .main-blog-arcire .box-video .box .flat-video-fancybox {
        padding: 0 130px;
    }

    .section-blog-details .blog-content .img-blog .meta {
        top: 39%;
        right: -69px;
        width: 200px;
        height: 45px;
    }

    /* end */
    .divider {
        width: 320px;
    }

    .divider::before {
        width: 160px;
    }

    .padding-responsive {
        padding: 0;
    }

    .section-blog.page-blog .themesflat-blog .meta .time {
        padding-right: 20px;
    }


    .has-bullets .owl-theme .owl-dots {
        display: none;
    }

    .section-slider.style-2 .tp-mask-wrap {
        margin-left: -17px;
    }

    .header .main-header.style-2 .main-header-contact {
        padding-right: 10px;
        float: right;
    }

    /* testimonial */
    .section-testimonials.style-3 .testimonials-item .thumb {
        padding: 70px 30px 70px 30px;
    }

    .section-testimonials.style-2 .description-right {
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 40px;
    }

    /* end */
    .page-title-about {
        padding: 170px 16px;
    }

    .section-work.style-2 .form-portfolio {
        padding: 25px 50px;
    }

    .section-portfolio .list-portfolio .boxitem .box-item-content .content-box h5.title {
        font-size: 16px;
    }

    .section-portfolio .list-portfolio .boxitem .box-item-content .content-box:after {
        right: 0px;
    }

    .section-video .box-video .box .flat-video-fancybox {
        padding: 0 20px;
    }

    .page-title {
        padding: 170px 0;
    }

    /* main Project Detail */
    .review .review-content .content .list-action li {
        padding-right: 10px;
    }

    .themesflat-contact-form.style-2 .wpcf7-form-control-wrap.your-name {
        margin-right: 12px;
    }

    .review .review-content .content {
        padding-left: 20px;
        padding-right: 30px;
    }

    .review .review-content .content .list-star {
        right: 20px;
    }

    .review .review-content.rep {
        padding-left: 50px;
    }

    /* end */
    /* box Author Project Details */
    .box-author li.fb-author {
        left: 30%;
    }

    .slidebar .box-search .search-form button.header-search-submit {
        right: 20px;
    }

    /* end */

    .section-contact .themesflat-contact-form.style-2 .wpcf7-form-control-wrap.your-name {
        margin-right: 12px;
    }

    /* couter */
    .section-counter {
        padding-bottom: 260px !important;
    }

    .section-counter .list-counter .themesflat-content-box {
        width: 50%;
        margin-bottom: 70px !important;
    }

    .section-counter .counter-item {
        padding: 0 !important;
    }

    .section-counter .list-counter .themesflat-content-box .counter-item .heading {
        margin-top: 20px;
    }

    .themesflat-counter.v1 {
        margin-left: 16px !important;
    }

    .themesflat-counter.v2 {
        margin-left: 15px !important;
    }

    .themesflat-counter.v3 {
        margin-left: 16px !important;
    }

    .themesflat-counter.v4 {
        margin-left: 16px !important;
    }

    /* end */

    /* pricing */
    .section-pricing .list-pricing {
        display: flex;
        flex-wrap: wrap;
    }

    .section-pricing .list-pricing .themesflat-pricing {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
    }

    .section-pricing .list-pricing .themesflat-pricing:nth-child(3) {
        margin-bottom: 0;
    }

    /* end */

    /* blog */
    .section-blog .list-blog {
        display: flex;
        flex-wrap: wrap;
    }

    .section-blog .list-blog .themesflat-blog {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
    }

    .section-blog .list-blog .themesflat-blog:nth-child(3) {
        margin-bottom: 0;
    }

    .themesflat-blog .meta {
        transform: rotate(-90deg) !important;
        bottom: 95px !important;
        right: -72px !important;
        width: 66% !important;
        height: 40px !important;
    }

    .section-blog-details .main-blog-detail {
        flex-wrap: wrap;
    }

    .section-blog-details .main-blog-detail .slidebar {
        width: 100%;
        padding-top: 0;
    }

    .section-blog-details .main-blog-detail .blog-content {
        width: 100%;
        padding-left: 0;
        padding-top: 50px;
    }

    /* end */
    .section-about.service-detail .intro-content img.headphone {
        top: 40px;
        left: 17px;
    }

    /* partner */
    .section-partner .themesflat-carousel-box.clearfix {
        padding: 0 20px;
    }

    /* end */
    /* footer */
    /* .footer .section-main-footer .list-footer .widget-about {
        width: 60%;
    } */

    /* .footer .section-main-footer .list-footer .widget-link {
        width: 40%;
        padding-left: 0;
        margin-top: 40px;
    } */
    .footer .section-main-footer .list-footer .widget-link {

        padding-left: 0;
        margin-top: 40px;
    }

    .footer .section-main-footer .list-footer .widget-link.v1 {
        padding-left: 20px;
        margin-top: 18px;
    }

    .footer .section-main-footer .list-footer .widget-link.v2 {
        padding-left: 0;
    }

    .footer .section-main-footer .list-footer .widget-recent-work {
        width: 100%;
        padding-left: 0;
        margin-top: 40px;
    }

    /* end */


}