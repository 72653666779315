:root {
    --main-color: #0446F1;
    --second-color: #3a70f8d5;
    --third-color: #FFA900;
    --light-color: #EFEFEF;
    --dark-color: #232323;

}

.section-custom-slider {
    position: relative;
    overflow: hidden;
    margin-top: -200px;
    height: 850px;
    background-color: black;

}

.section-custom-slider .carousel {
    
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.section-custom-slider .carousel img {
    height: 850px;
    object-fit: cover;
}

.section-custom-slider .carousel .custom-caption {
    margin-top: 100px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
   
}
html[lang="en" ] .section-custom-slider .carousel .custom-caption {
 
    text-align: left;
    padding-left: 10rem;
}
html[lang="ar" ] .section-custom-slider .carousel .custom-caption {
 
   
    text-align: right;
    padding-right: 10rem;
}

.carousel .custom-caption div {
    margin: 2rem 0;
    color: var(--light-color);
}

.carousel .custom-caption div h3,
.carousel .custom-caption div h5,
.carousel .custom-caption div h2 {

    color: var(--light-color);
}

.carousel .custom-caption div h2 {
    font-weight: 700;
    color: var(--main-color);
}
.carousel .custom-caption div h3
{    font-weight: 700;
    letter-spacing: 1rem;
}