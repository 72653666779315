@media (max-width: 990px) {
    .title-div.title-of-portfolio {
        text-align: start !important;
    }
}
@media (max-width: 450px) {
    .title-div.title-of-portfolio {
        width: fit-content;
    }
    .row-of-portfolio-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .filter-div {
        width: 20px;
        margin: 0 10px ;
    }
}
@media (max-width: 600px) {
    .filter-div .filter-text{
        display: none;
    }
}